<script setup>
import dayjs from 'dayjs';
import { cloneDeep, isNil, uniq } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { nextTick } from 'vue';
import { useEvents } from '~/project-management/composables/pm-events.composable';
import { useHelpers } from '~/project-management/composables/pm-helpers.composable';
import { useProjectManagementStore } from '~/project-management/store/pm.store';
import { generateActivityUid } from '~/project-management/utils/pm-helper.utils';

const props = defineProps({
  activity: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['close', 'save']);

const $t = inject('$t');

const { createSurrogateTask } = useEvents();
const project_management_store = useProjectManagementStore();
const { handleStartDateChangeForProjects } = useHelpers();
const { $g, active_schedule, flags, is_fullscreen } = storeToRefs(project_management_store);
const { modify_config, change_activity_id, handle_subtask_addition } = project_management_store;

const state = reactive({
  data: [],
  form_data: {
    add_rows_bottom: 10,
  },
  is_saving: false,
  existing_ids: [],
  hands_on_table_instance: null,
  invalid_data: {
    is_table_empty: false,
    has_duplicate_ids: false,
    project_table_empty: false,
    invalid_weight_sum: false,
    current_weight_sum: 0,
    predecessors: [],
  },
  is_save_disabled: false,
});

const formatter = $g.value.ext.formatters.durationFormatter({
  enter: 'day',
  store: 'day',
  format: 'day',
  short: true,
  hoursPerWeek: 56,
  labels: {
    day: {
      full: 'day',
      plural: 'days',
      short: 'd',
    },
    week: {
      full: 'week',
      plural: 'weeks',
      short: 'w',
    },
    month: {
      full: 'month',
      plural: 'months',
      short: 'm',
    },
    year: {
      full: 'year',
      plural: 'years',
      short: 'y',
    },
  },
});

const linksFormatter = $g.value.ext.formatters.linkFormatter({ durationFormatter: formatter });

const customLinkFormatter = {
  format(link) {
    let formatted_value = `${link.source}`;
    if (link.type !== 'FS' || (link.lag && link.lag !== 0))
      formatted_value += ` ${link.type}`;
    if (link.lag && link.lag !== 0)
      formatted_value += `${link.lag > 0 ? '+' : ''}${link.lag}${$t('days')}`;
    return formatted_value;
  },
  parse(formatted_value) {
    formatted_value = formatted_value.trim();

    const pattern = /^(\S*)(?:\s.*)?$/;
    let result = formatted_value.replace(pattern, (match, p1) => {
      const new_value = $g.value.getTask(p1);
      return formatted_value.replace(p1, $g.value.getWBSCode(new_value));
    });
    result = result.replaceAll(' ', '');
    const link = linksFormatter.parse(result);
    return link;
  },
};

const is_save_disabled = computed(() => {
  return !!state.existing_ids.length
    || state.invalid_data.is_table_empty
    || state.invalid_data.has_duplicate_ids
    || state.invalid_data.project_table_empty
    || state.invalid_data.invalid_weight_sum
    || !!state.invalid_data.predecessors.length
    || state.is_save_disabled;
});

const hot_columns = computed(() => {
  const columns = [
    {
      data: 'uid',
      header: 'UID',
    },
    {
      data: 'id',
      config: {
        required: true,
      },
      validator: 'default-validator',
      header: $t('ID'),
    },
    {
      data: 'text',
      config: {
        required: true,
      },
      width: '130px',
      validator: 'default-validator',
      header: $t('Activity'),
    },
    {
      data: 'start_date',
      type: 'date',
      dateFormat: 'DD MMMM YYYY',
      config: {
        required: true,
        type: 'date',
        can_select_past_dates: true,
      },
      validator: 'default-validator',
      header: $t('Planned Start'),
    },
    {
      data: 'duration',
      type: 'numeric',
      config: {
        required: true,
        field_type: 'numeric',
        min: 0,
        max: 10000,
      },
      validator: 'default-validator',
      header: $t('Duration'),
    },
    {
      data: 'end_date',
      readOnly: true,
      renderer: endDateRenderer,
      header: $t('Planned Finish'),
    },
    ...(active_schedule.value.has_activity_weightages
      ? [
          {
            data: 'weight',
            type: 'numeric',
            config: {
              field_type: 'numeric',
              min: 0,
              max: 100,
            },
            validator: 'default-validator',
            header: $t('Weight'),
          },
        ]
      : []),
    {
      data: 'predecessors',
      type: 'text',
      header: $t('Predecessors'),
    },
  ];

  return columns;
});

const hot_table_height = computed(() => {
  let calculated_height = (state.data.length + 1) * 30 + 7;
  if (calculated_height > 500)
    calculated_height = 500;
  return `${calculated_height}px`;
});

const activity_display_text = computed(() => {
  const activity = $g.value.getTask(props.activity.id);
  return `${activity.text} (${activity.id})`;
});

const children_activities_ids = computed(() => {
  return $g.value.getChildren(props.activity.id);
});

const children_activities = computed(() => {
  return children_activities_ids.value
    .map(child => $g.value.getTask(child))
    .filter(activity => activity.type !== $g.value.config.types.surrogate);
});

function endDateRenderer(instance, td, row, _col, _prop, _value, _cellProperties) {
  td.classList.add('read-only-cell', 'htMiddle');
  const hot_row_data = instance.getData()[row];
  if (hot_row_data[3] && (!isNil(hot_row_data[4]) && hot_row_data[4] !== '')) {
    if (hot_row_data[4] > 10000 || hot_row_data[4] < 0) {
      td.textContent = '';
      return td;
    }
    const start_date = dayjs(hot_row_data[3]);
    if (start_date.isValid()) {
      if (hot_row_data[4] === 0) {
        td.textContent = dayjs($g.value.calculateEndDate(dayjs(start_date).startOf('day').toDate(), hot_row_data[4])).format('DD MMMM YYYY');
        return td;
      }
      else {
        td.textContent = dayjs($g.value.calculateEndDate(dayjs(start_date).startOf('day').toDate(), hot_row_data[4])).subtract(1, 'day').format('DD MMMM YYYY');
      }
    }
    return td;
  }
  td.textContent = '';
  return td;
}

function textRenderer(instance, td, row, col, _prop, _value, _cellProperties) {
  const hot_row_data = instance.getData()[row];
  td.textContent = hot_row_data[col];
  td.classList.add('htMiddle');

  const task = active_schedule.value.activities?.[hot_row_data[0]];

  if (!hot_row_data[2] && !isEmptyRow(hot_row_data.slice(1)))
    td.classList.add('htInvalid');

  const unique_id = `cool-element-${hot_row_data[0]}`;

  let task_type = null;
  if (hot_row_data[4] === 0) {
    task_type = 'MILESTONE';
  }
  else if (task && task._original_type === $g.value.config.types.wbs) {
    task_type = 'WBS';
  }
  if (!task_type) {
    return td;
  }
  const cool_element = document.createElement('span');
  cool_element.id = unique_id;
  cool_element.textContent = task_type;
  cool_element.classList.add('bg-gray-500', 'text-gray-100', 'mr-1', 'text-[9px]', 'px-1', 'py-[0px]', 'rounded-sm', 'absolute', 'right-0', 'top-1/2', '-translate-y-1/2', 'flex', 'items-center', 'justify-center', 'h-[18px]', 'font-medium', 'hover:opacity-0', 'transition-opacity', 'duration-200');
  cool_element.classList.add(task_type === 'MILESTONE' ? 'bg-primary-500' : 'bg-warning-500');

  td.appendChild(cool_element);
  td.classList.add('relative');

  return td;
}

function checkEmptyActivity(activity) {
  const copied_activity = cloneDeep(activity);
  if (copied_activity.uid)
    delete copied_activity.uid;
  return Object.values(activity).every((value) => {
    return value === null || value === '' || value === undefined || value?.length === 0;
  });
}

function isEmptyRow(row_data) {
  if (!row_data)
    return true;
  return row_data.every((value) => {
    return value === null || value === '' || value === undefined || value?.length === 0;
  });
}

function validatePredecessors(ids) {
  state.data.forEach((activity) => {
    if (activity.predecessors) {
      const sources = activity.predecessors?.split?.(',');
      sources?.forEach?.((value) => {
        const id = value.trim().split(' ')[0];

        const does_exist_in_gantt = $g.value.isTaskExists(id);
        if (!ids.includes(id) && !does_exist_in_gantt) {
          state.invalid_data.predecessors.push(id);
        }
        else if (!ids.includes(id) && does_exist_in_gantt) {
          const parent_id = $g.value.getParent(id);
          const parent_task = $g.value.getTask(parent_id);
          if (parent_task?.id === props.activity.id) {
            state.invalid_data.predecessors.push(id);
          }
        }
      });
    }
  });
  state.invalid_data.predecessors = uniq(state.invalid_data.predecessors);
}

function preprocessData(data) {
  return data.map((activity) => {
    return {
      uid: activity[0],
      id: activity[1],
      text: activity[2],
      start_date: activity[3],
      duration: activity[4],
      ...(active_schedule.value.has_activity_weightages ? { weight: activity[6] } : {}),
      predecessors: activity[active_schedule.value.has_activity_weightages ? 7 : 6],
    };
  });
}

async function onSave() {
  state.invalid_data.is_table_empty = false;
  state.invalid_data.has_duplicate_ids = false;
  state.invalid_data.project_table_empty = false;
  state.invalid_data.invalid_weight_sum = false;
  state.invalid_data.predecessors = [];
  state.is_saving = true;

  // Remove rows that are empty
  const indices_to_be_removed = [];
  preprocessData(state.hands_on_table_instance.getData()).forEach((activity, index) => {
    const copied_activity = {
      id: activity.id,
      text: activity.text,
      start_date: activity.start_date,
      duration: activity.duration,
      weight: activity.weight,
      predecessors: activity.predecessors,
    };
    if (!active_schedule.value.has_activity_weightages)
      delete copied_activity.weight;
    const is_empty_task = checkEmptyActivity(copied_activity);
    if (is_empty_task)
      indices_to_be_removed.push(index - indices_to_be_removed.length);
  });

  indices_to_be_removed.forEach((index) => {
    if (state.data.length === 1 && children_activities.value.length === 0) {
      state.invalid_data.is_table_empty = true;
      return;
    }
    state.hands_on_table_instance.alter('remove_row', index);
  });
  // If the table is empty and there are no existing children, throw error
  if (state.data.length === 1 && state.invalid_data.is_table_empty) {
    await nextTick();
    state.is_saving = false;
    return;
  }
  // If the table is empty and there are existing children, delete all the children
  else if (state.data.length === 0) {
    if (props.activity.is_root) {
      await nextTick();
      state.is_saving = false;
      state.invalid_data.project_table_empty = true;
      return;
    }
    setTimeout(() => {
      const task = $g.value.getTask(props.activity.id);
      task.type = $g.value.config.types.task;
      task._original_type = $g.value.config.types.task;
      children_activities_ids.value.forEach((child) => {
        $g.value.deleteTask(child);
      });
      $g.value.updateTask(props.activity.id);
      $g.value.autoSchedule();
      flags.value.activities_updated_count++;
      emit('close');
    }, 1000);
  }
  else {
    state.invalid_data.is_table_empty = false;
    state.invalid_data.project_table_empty = false;
  }

  // If there are duplicate IDs within the table, throw error
  const ids = state.data.map(activity => activity.id);
  if (ids.length !== new Set(ids).size) {
    await nextTick();
    state.is_saving = false;
    state.invalid_data.has_duplicate_ids = true;
    state.invalid_data.is_table_empty = false;
    return;
  }

  if (active_schedule.value.has_activity_weightages) {
    const weight_sum = state.data.reduce((acc, activity) => {
      return acc + (activity.weight || 0);
    }, 0);
    const null_weights_present = state.data.some(activity => activity.weight === null || activity.weight === undefined || activity.weight === '');
    if ((weight_sum > 100) || (weight_sum < 100 && !null_weights_present)) {
      await nextTick();
      state.is_saving = false;
      state.invalid_data.invalid_weight_sum = true;
      state.invalid_data.current_weight_sum = weight_sum;
      return;
    }
  }

  validatePredecessors(ids);
  if (state.invalid_data.predecessors.length) {
    await nextTick();
    state.is_saving = false;
    return;
  }

  // The below setTimeout is needed to display a loader while saving the data
  await new Promise(resolve => setTimeout(resolve, 200));

  // If the same ID is present anywhere in the Gantt (outside the table), throw error
  state.existing_ids = [];
  state.data.forEach((child) => {
    if (children_activities_ids.value.includes(child.id)) {
      return;
    }
    if ($g.value.isTaskExists(child.id)) {
      state.existing_ids.push(child.id);
      state.existing_ids = uniq(state.existing_ids);
    }
  });
  if (state.existing_ids.length) {
    await nextTick();
    state.is_saving = false;
    return;
  }

  // Data processing
  const saved_data = preprocessData(state.hands_on_table_instance.getData()).map((activity) => {
    return {
      ...activity,
      id: activity.id?.trim?.()?.replaceAll?.(' ', ''),
      start_date: dayjs(activity.start_date).startOf('day').toDate(),
    };
  });

  // If no subtask, add all tasks
  if (children_activities.value.length === 0) {
    saved_data.forEach((child) => {
      const new_task_object = {
        ...child,
        readonly: false,
        status: 'Not started',
        parent: props.activity.id,
        uid: generateActivityUid(),
        is_backend_save_pending: true,
        type: child.duration === 0 ? $g.value.config.types.milestone : $g.value.config.types.task,
      };
      delete new_task_object.predecessors;
      $g.value.addTask(new_task_object);
      active_schedule.value.activities[new_task_object.uid] = new_task_object;
    });
    createSurrogateTask(props.activity.id);
    const parent_task = $g.value.getTask(props.activity.id);
    handle_subtask_addition(parent_task);
  }
  // If subtasks are present, check if the uid is present in the data. If yes, update the existing task. Else create a new task.
  else {
    // Remove the tasks that are not present in the table
    children_activities.value.forEach((child) => {
      if (!saved_data.find(activity => activity.uid === child.uid)) {
        $g.value.deleteTask(child.id);
      }
    });

    // Update the existing tasks and add new tasks
    saved_data.forEach((child, index) => {
      const existing_task = children_activities.value.find(activity => activity.uid === child.uid);
      if (existing_task) {
        if (existing_task.id !== child.id)
          change_activity_id(existing_task.id, child.id);
        if (existing_task.type === $g.value.config.types.project) {
          handleStartDateChangeForProjects(existing_task.id, 'start_date', child.start_date);
          existing_task.text = child.text;
          existing_task.weight = child.weight;
          $g.value.updateTask(existing_task.id);
          const current_index = $g.value.getTaskIndex(existing_task.id);
          if (current_index !== index) {
            const parent = $g.value.getParent(existing_task.id);
            $g.value.moveTask(existing_task.id, index, parent);
          }
          return;
        }
        existing_task.text = child.text;
        existing_task.start_date = child.start_date;
        existing_task.duration = child.duration;
        existing_task.constraint_type = 'snet';
        existing_task.constraint_date = existing_task.start_date;
        if (existing_task.duration === 0) {
          existing_task.type = $g.value.config.types.milestone;
          existing_task._original_type = $g.value.config.types.milestone;
        }
        else {
          existing_task.type = $g.value.config.types.task;
          existing_task._original_type = $g.value.config.types.task;
        }
        existing_task.end_date = $g.value.calculateEndDate(child.start_date, child.duration);
        existing_task.weight = child.weight;
        $g.value.updateTask(existing_task.id);
        const current_index = $g.value.getTaskIndex(existing_task.id);
        if (current_index !== index) {
          const parent = $g.value.getParent(existing_task.id);
          $g.value.moveTask(existing_task.id, index, parent);
        }
      }
      else {
        const new_task_object = {
          ...child,
          readonly: false,
          status: 'Not started',
          parent: props.activity.id,
          uid: generateActivityUid(),
          is_backend_save_pending: true,
          type: child.duration === 0 ? $g.value.config.types.milestone : $g.value.config.types.task,
        };
        delete new_task_object.predecessors;
        $g.value.addTask(new_task_object, props.activity.id, index);
        active_schedule.value.activities[new_task_object.uid] = new_task_object;
      }
    });
  }

  // Parse the predecessors using the Gantt's custom link formatter
  const links = [];
  saved_data.forEach((activity) => {
    if (activity.predecessors) {
      const sources = activity.predecessors?.split?.(',');
      sources?.forEach?.((value) => {
        const link = customLinkFormatter.parse(value);
        link.target = activity.id;
        if ($g.value.isTaskExists(link.source) || ids.includes(link.source)) {
          links.push(link);
        }
      });
    }
  });
  // Removing existing links that are not present in the table
  const existing_links = $g.value.getLinks().filter(link => children_activities_ids.value.includes(link.target));
  existing_links.forEach((link) => {
    if (!links.find(l => l.source === link.source && l.target === link.target)) {
      $g.value.deleteLink(link.id);
    }
  });
  // If there is an existing link, ignore it. Else, add the link
  links.forEach((link) => {
    if (!existing_links.find(l => l.source === link.source && l.target === link.target)) {
      $g.value.addLink(link);
    }
    else {
      const link_to_update = existing_links.find(l => l.source === link.source && l.target === link.target);
      link_to_update.type = link.type;
      link_to_update.lag = link.lag;
      $g.value.updateLink(link_to_update.id);
    }
  });

  // Open the parent, change WBS levels and change task types
  $g.value.getTask(props.activity.id).$open = true;
  modify_config({ key: 'wbs_level', value: Number.MAX_SAFE_INTEGER });
  modify_config({ key: 'wbs_level_max', value: Number.MAX_SAFE_INTEGER });
  if (!props.activity.is_root) {
    $g.value.getTask(props.activity.id).type = $g.value.config.types.project;
    $g.value.getTask(props.activity.id)._original_type = $g.value.config.types.wbs;
  }
  $g.value.updateTask(props.activity.id);
  $g.value.autoSchedule();
  flags.value.activities_updated_count++;
  emit('close');
}

function onHandsOnTableReady(event) {
  state.is_loading = false;
  state.hands_on_table_instance = event;
  state.hands_on_table_instance.updateSettings({
    cells(row, col) {
      const row_data = state.hands_on_table_instance.getData()[row];
      const cells_object = {};
      if (row_data?.[1]) {
        const task = $g.value.getTask(row_data[1]);
        if (col === 2 && row_data[col]) {
          cells_object.renderer = textRenderer;
        }
        state.existing_ids = [];
        state.data.forEach((child) => {
          if (children_activities_ids.value.includes(child.id)) {
            return;
          }
          if ($g.value.isTaskExists(child.id)) {
            state.existing_ids.push(child.id);
            state.existing_ids = uniq(state.existing_ids);
          }
        });
        if (state.existing_ids.length)
          return;
        if (task && col === 4 && [$g.value.config.types.project].includes(task.type)) {
          cells_object.readOnly = true;
          cells_object.validator = undefined;
        }
      }
      return cells_object;
    },
  });
}

function afterCreateRow(...args) {
  state.hands_on_table_instance.setDataAtCell(args[0], 0, `hot-${crypto.randomUUID().substring(0, 8)}`);
}

function afterColumnResize() {
  state.hands_on_table_instance?.render();
}

function beforeValidate(_val, row, _prop) {
  const hot_row_data = state.hands_on_table_instance.getData()[row];
  if (isEmptyRow(hot_row_data?.slice?.(1))) {
    return 0;
  }
}

watch(() => state.data.length, () => {
  let calculated_height = (state.data.length + 1) * 30 + 7;
  if (calculated_height > 500)
    calculated_height = 500;

  state.hands_on_table_instance?.updateSettings({
    height: `${calculated_height}px`,
  });
}, { immediate: true });

watch(() => state.data, () => {
  if (state.is_saving)
    return;
  state.invalid_data.is_table_empty = false;
  state.invalid_data.has_duplicate_ids = false;
  state.invalid_data.project_table_empty = false;
  state.invalid_data.invalid_weight_sum = false;
  state.invalid_data.predecessors = [];
  state.hands_on_table_instance?.validateCells?.((is_valid) => {
    state.is_save_disabled = !is_valid;
  });
}, { deep: true });

onMounted(() => {
  state.is_loading = true;
  if (children_activities.value.length) {
    state.data = children_activities.value.map((activity) => {
      const links = activity.$target;
      let predecessors = [];
      for (const link_item of links) {
        const link = $g.value.getLink(link_item);
        predecessors.push(customLinkFormatter.format(link));
      }
      predecessors = predecessors.join(', ');
      return {
        id: activity.id,
        uid: activity.uid,
        text: activity.text,
        start_date: dayjs(activity.start_date).format('DD MMMM YYYY'),
        duration: activity.duration,
        weight: activity.weight,
        predecessors,
      };
    });
  }
  const empty_item = { id: null, uid: null, text: null, start_date: null, duration: null, weight: null, predecessors: '' };
  while (state.data.length < 15) {
    const new_item_uid = `hot-${crypto.randomUUID().substring(0, 8)}`;
    empty_item.uid = new_item_uid;
    state.data.push(cloneDeep(empty_item));
  }

  document.addEventListener('Enter', (event) => {
    const el = document.querySelector('.pika-single');
    if (el) {
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  });
});
</script>

<template>
  <HawkModalContainer
    :options="{ teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body', escToClose: false }"
    content_class="w-[80vw]"
  >
    <Vueform
      v-model="state.form_data"
      sync
      size="sm"
      :display-errors="false"
      :display-messages="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            {{ $filters.truncate(activity_display_text, 80) }}
            <span class="font-normal text-sm text-gray-500">
              {{ $t('Add/manage subtasks') }}
            </span>
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <HawkLoader v-if="state.is_loading" />
          <div v-show="!state.is_loading">
            <div
              v-if="
                props.activity.type === $g.config.types.task
                  || props.activity.$target.length
              "
              class="mb-6 border border-gray-300 bg-gray-25 flex items-center gap-3 rounded-lg p-4 w-fit"
            >
              <IconHawkInfoCircle class="text-gray-600" />
              <div class="text-sm font-normal text-gray-700 w-fit">
                <template v-if="props.activity.type === $g.config.types.task">
                  {{ $t('The activity') }} <span class="font-semibold">{{ activity_display_text }}</span> {{ $t('will be converted to type WBS and the data in the below table will be its children.') }}
                </template>
                <template v-if="props.activity.$target.length">
                  <span class="font-semibold">{{ activity_display_text }}</span> {{ $t('is a target of a few link(s). Therefore, the planned start dates mentioned in this table may be ignored.') }}
                </template>
              </div>
            </div>
            <HawkHandsontable
              :hot-settings="{
                datePickerConfig: {
                  disableDayFn(date) {
                    return !$g?.isWorkTime?.(date);
                  },
                },
                rowHeights: '30px',
                className: 'htMiddle',
                manualColumnResize: true,
                hiddenColumns: {
                  columns: [0],
                  copyPasteEnabled: false,
                  indicators: true,
                },
                afterCreateRow,
                afterColumnResize,
                beforeValidate,
              }"
              :row-sorting="false"
              :data="state.data"
              :columns="hot_columns"
              :col-headers="hot_columns.map(column => column.header)"
              :columns-menu="{ items: {} }"
              :height="hot_table_height"
              class="pm-excel-modal"
              @ready="onHandsOnTableReady"
            />
            <div v-if="state.existing_ids.length" class="text-sm text-error-600 mt-3">
              {{ $t('The following ID(s) already exist in the schedule') }}:
              <span class="font-semibold">
                {{ state.existing_ids.join(', ') }}
              </span>
            </div>
            <div v-if="state.invalid_data.is_table_empty" class="text-sm text-error-600 mt-3">
              {{ $t('The table cannot be empty') }}.
            </div>
            <div v-else-if="state.invalid_data.has_duplicate_ids" class="text-sm text-error-600 mt-3">
              {{ $t('The table cannot have duplicate IDs') }}.
            </div>
            <div v-else-if="state.invalid_data.predecessors.length" class="text-sm text-error-600 mt-3">
              {{ $t('Invalid predecessors found. The following ID(s) do not exist') }}:
              <span class="font-semibold">
                {{ state.invalid_data.predecessors.join(', ') }}
              </span>
            </div>
            <div v-else-if="state.invalid_data.project_table_empty" class="text-sm text-error-600 mt-3">
              {{ $t('The project must have at least one task') }}.
            </div>
            <div v-else-if="state.invalid_data.invalid_weight_sum && state.invalid_data.current_weight_sum > 100" class="text-sm text-error-600 mt-3">
              {{ $t('The sum of the weights cannot exceed') }} 100 ({{ $t('exceeding by') }} {{ state.invalid_data.current_weight_sum - 100 }})
            </div>
            <div v-else-if="state.invalid_data.invalid_weight_sum && state.invalid_data.current_weight_sum < 100" class="text-sm text-error-600 mt-3">
              {{ $t('The sum of the weights cannot be less than') }} 100 ({{ $t('remaining') }}: {{ 100 - state.invalid_data.current_weight_sum }})
            </div>
          </div>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end w-full col-span-full">
              <ButtonElement
                class="mr-4"
                :secondary="true"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </ButtonElement>
              <ButtonElement
                name="save"
                :disabled="is_save_disabled"
                :loading="state.is_saving"
                submits
              >
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>

<style lang="scss">
.pm-excel-modal {
  .changeType {
    @apply hidden;
  }

  .handsontableInput {
    line-height: 29px;
  }

  th.afterHiddenColumn::before{
    content: '' !important;
  }
}
</style>
