<script setup>
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useRoute, useRouter } from 'vue-router';
import { useChatStore } from '~/common/stores/chat.store.js';
import { useI18nStore } from '~/common/stores/i18n.store.js';
import { usePusherStore } from '~/common/stores/pusher.store.js';
import PmActivityTrackingModal from '~/project-management/components/activity-details/pm-activity-tracking-modal.vue';
import PmFieldMappingsModal from '~/project-management/components/activity-details/pm-field-mappings-modal.vue';
import PmNewDependencyModal from '~/project-management/components/activity-details/pm-new-dependency-modal.vue';
import PmReferencesModal from '~/project-management/components/activity-details/pm-references-modal.vue';
import PmUpdateAutoProgress from '~/project-management/components/activity-details/pm-update-auto-progress.vue';
import PmUpdateProgress from '~/project-management/components/activity-details/pm-update-progress.vue';
import { useAttachments } from '~/project-management/composables/pm-attachments.composable.js';
import { useHelpers } from '~/project-management/composables/pm-helpers.composable';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const emit = defineEmits(['reloadSlideOver']);

provide('location', 'pm-activity-details');

const $t = inject('$t');

const route = useRoute();
const router = useRouter();
const chat_store = useChatStore();
const i18n_store = useI18nStore();
const pusher_store = usePusherStore();
const project_management_store = useProjectManagementStore();

const { attachment_upload_modal } = useAttachments();
const { openDeleteTaskPopup } = useHelpers();

const {
  refetch_activity,
  set_active_task_uid,
  set_references,
  set_schedule_dirtiness,
  set_pm_attachments,
  view_activity,
} = project_management_store;

const { PUSHER, is_initialized } = storeToRefs(pusher_store);
const {
  $g,
  flags,
  active_task,
  active_task_uid,
  active_view,
  active_schedule,
  activity_pusher_channel,
  schedule_dirtiness,
  is_schedule_editable,
  is_fullscreen,
  references,
  triggered_by,
  activity_details_page,
} = storeToRefs(project_management_store);
const { current_lang } = storeToRefs(i18n_store);

const state = reactive({
  dependencies: [],
  is_visible: true,
  is_loading: true,
  is_add_button_open: false,
  is_activity_details_loading: false,
});

const update_progress_modal = useModal({
  component: PmUpdateProgress,
  attrs: {
    onClose() {
      update_progress_modal.close();
    },
  },
});

const references_modal = useModal({
  component: PmReferencesModal,
  attrs: {
    onClose: () => {
      references_modal.close();
    },
  },
});

const new_dependency_modal = useModal({
  component: PmNewDependencyModal,
  attrs: {
    onClose: () => {
      new_dependency_modal.close();
    },
  },
});

const field_mappings_modal = useModal({
  component: PmFieldMappingsModal,
  attrs: {
    onClose: () => {
      field_mappings_modal.close();
    },
  },
});

const update_progress_auto_modal = useModal({
  component: PmUpdateAutoProgress,
  attrs: {
    onClose() {
      update_progress_auto_modal.close();
    },
  },
});

const activity_tracking_modal = useModal({
  component: PmActivityTrackingModal,
  attrs: {
    onClose: () => {
      activity_tracking_modal.close();
    },
  },
});

const task_status_color_map = {
  'Not Started': 'gray',
  'In Progress': 'blue',
  'Completed': 'green',
};

const hierarchy_breadcrumbs = computed(() => {
  const parents = [];
  $g.value.eachParent((task) => {
    parents.push({
      value: task.uid,
      label: task.text,
      uid: task.id,
      has_children: true,
      truncate_length: Math.min(task.text?.length, 20),
    });
  }, active_task.value.id);
  return parents.reverse();
});

const is_group_by_length_zero = computed(() => active_view.value.data.group_by.length === 0);

const add_button_items = computed(() => {
  return [
    [
      ...(is_schedule_editable.value
        ? [
            {
              label: $t('Dependency'),
              uid: 'dependency',
              on_click: () => {
                onAddDependency();
              },
            },
          ]
        : []),
      ...(!active_task.value.is_backend_save_pending
        ? [{
            label: $t('Attachment'),
            uid: 'attachment',
            on_click: () => {
              onAddAttachmentsClicked();
            },
          },
          ]
        : []),
      ...(!is_schedule_editable.value
        ? [
            {
              label: $t('Reference'),
              uid: 'reference',
              on_click: () => {
                references_modal.open();
              },
            },
          ]
        : []),
      ...(!is_schedule_editable.value && active_task.value.actions.track && [$g.value.config.types.task, $g.value.config.types.milestone].includes(active_task.value.type)
        ? [
            {
              label: $t('Activity tracking'),
              uid: 'activity_tracking',
              on_click: () => {
                onAddActivityTracking();
              },
            },
          ]
        : []),
    ],
  ];
});

const three_dot_items = computed(() => {
  const items = [];

  if (
    !is_schedule_editable.value
    && active_task.value.type === $g.value.config.types.task
  ) {
    if (active_task.value.actions.modify_progress_integration) {
      items.push([
        'Setup progress sync',
        'setup_progress_sync',
        () => {
          update_progress_auto_modal.open();
        },
      ]);
    }
    if (!active_task.value.auto_progress_sync?.is_enabled && active_task.value.actions.update_progress) {
      items.push([
        'Update progress',
        'update_progress',
        () => {
          update_progress_modal.open();
        },
      ]);
    }
  }

  if (is_schedule_editable.value) {
    if (active_task.value._original_type !== $g.value.config.types.project) {
      items.push([
        'Delete',
        'delete',
        () => {
          openDeleteTaskPopup(active_task.value, () => {
            closeDetails();
          });
        },
      ]);
    }
  }

  else {
    items.push(
      ...(active_task.value?.actions?.modify_property_mappings
        ? [[
            'Sync properties',
            'field_mappings',
            () => {
              field_mappings_modal.open();
            },
          ]]
        : []),
      [
        'Progress history',
        'progress_history',
        () => {
          activity_details_page.value = 'progress-history';
        },
      ],
    );
  }

  return items.map((item) => {
    return {
      label: $t(item[0]),
      uid: item[1],
      on_click: item[2],
    };
  });
});

const attachments = computed(() => {
  const result = active_task.value?.attachments || [];
  return result.map(item => ({
    ...item,
    notes: item.note || null,
  }));
});

const is_empty_page = computed(() => {
  return !state.dependencies.length
    && !attachments.value.length
    && !references.value.length
    && (
      is_schedule_editable.value
      || !active_schedule.value?.trackings?.filter?.(tracking => tracking.activity === active_task_uid.value)?.length
    );
});

function closeDetails() {
  activity_details_page.value = '';
  PUSHER.value.unsubscribe(`presence-TASKS_TICKET_PM_${active_task_uid.value}`);
  activity_pusher_channel.value = null;
  set_active_task_uid(null);
}

function onAddDependency() {
  new_dependency_modal.patchOptions({
    attrs: {
      mode: 'add',
      initialData: {
        type: 'FS',
        lag: 0,
      },
      dependencies: state.dependencies,
    },
  });
  new_dependency_modal.open();
}

function onEditDependency(item) {
  const link = $g.value.getLink(item.link_id);

  new_dependency_modal.patchOptions({
    attrs: {
      mode: 'edit',
      initialData: {
        ...item,
        lag: Number.parseInt(item.lag, 10),
        activities: active_task_uid.value === link.target ? [link.source] : [link.target],
        source_or_target: item.source_or_target,
        dependencies: [],
      },
    },
  });
  new_dependency_modal.open();
}

async function onAddAttachmentsClicked() {
  if (is_fullscreen.value)
    await document.exitFullscreen();

  setTimeout(() => {
    attachment_upload_modal.open();
  }, 10);
}

function onAddActivityTracking() {
  activity_tracking_modal.patchOptions({
    attrs: {
      activity: active_task.value,
    },
  });
  activity_tracking_modal.open();
}

function setupPusherChannel(new_id, old_id) {
  if (!PUSHER.value?.subscribe || !is_initialized.value || !active_schedule.value)
    return;

  if (old_id) {
    PUSHER.value.unsubscribe(`presence-TASKS_TICKET_PM_${old_id}`);
    activity_pusher_channel.value = null;
  }

  activity_pusher_channel.value = PUSHER.value.subscribe(`presence-TASKS_TICKET_PM_${new_id}`);
}

watchEffect(() => {
  if (state.is_visible === true) {
    const app = document.getElementById('app');
    setTimeout(() => {
      app.removeAttribute('inert');
    }, 100);
  }
});

watch(active_task_uid, async (new_id, old_id) => {
  state.is_loading = true;
  if (triggered_by.value === 'row-attachments' && !attachments.value?.length)
    onAddAttachmentsClicked();

  setupPusherChannel(new_id, old_id);
  state.is_activity_details_loading = true;
  await view_activity();
  const dirtiness_stored = schedule_dirtiness.value;
  if (!is_schedule_editable.value || !active_task.value.is_backend_save_pending) {
    await refetch_activity(active_task_uid.value, ['attachments', 'comments']);
  }
  state.is_activity_details_loading = false;
  await set_pm_attachments();
  set_schedule_dirtiness(dirtiness_stored);

  if (!is_schedule_editable.value || !active_task.value.is_backend_save_pending)
    await set_references($t);

  state.is_loading = false;
}, { immediate: true });

watch(() => [active_schedule.value.relations, active_task_uid.value], () => {
  const active_task_obj = $g.value.getTask(active_task.value?.id);
  const sourceDeps = active_task_obj.$source.map(link_id => $g.value.getLink(link_id));
  const targetDeps = active_task_obj.$target.map(link_id => $g.value.getLink(link_id));

  const mapDeps = (deps, sourceOrTarget) => deps.map((dep) => {
    const task = $g.value.getTask(
      dep[sourceOrTarget === 'source' ? 'target' : 'source'],
    );

    if (!task)
      return null;

    const lag = Math.ceil(dep.lag ?? 0);

    return {
      link_id: dep.id,
      link_uid: dep.uid,
      id: task.id,
      uid: task.uid,
      wbs: $g.value.getWBSCode(task),
      name: task.text,
      type: dep.type,
      lag: lag === 1 ? `1 ${$t('day')}` : `${lag} ${$t('days')}`,
      status: task.status ? $t(task.status) : task.status,
      source_or_target: sourceOrTarget,
    };
  });

  state.dependencies = [
    ...mapDeps(sourceDeps, 'source'),
    ...mapDeps(targetDeps, 'target'),
  ];
}, { immediate: true });

watch(() => flags.value.attachments_trigger_count, () => {
  if (triggered_by.value === 'row-attachments' && !attachments.value?.length)
    onAddAttachmentsClicked();
});

watch(() => route.query, (_new_query, old_query) => {
  if (old_query.task) {
    const uid = active_task.value.uid;
    router.push(
      {
        ...router.currentRoute.value,
        query: { ...(uid && { activity: uid }) },
      },
    );
    emit('reloadSlideOver');
  }
}, { deep: true });
</script>

<template>
  <div class="px-6 pt-6 top-0 left-0 bg-white z-[2001]">
    <div class="flex">
      <div class="flex flex-col gap-1">
        <HawkBreadcrumbs
          v-if="is_group_by_length_zero"
          :items="hierarchy_breadcrumbs"
          :show_active_color="false"
          :max_tags_to_display="3"
          @crumb-clicked="activity => set_active_task_uid(activity.value)"
        />
        <div v-else />
        <div class="flex items-center gap-1 font-semibold text-gray-900 text-md w-[500px] break-all">
          {{ active_task?.text }} ({{ active_task?.id }})
        </div>
      </div>
      <div class="flex items-center h-10 gap-1 ml-auto">
        <div v-if="active_task.status" class="cursor-text">
          <HawkBadge
            size="md"
            class="pointer-events-none"
            custom_classes="whitespace-nowrap"
            :color="task_status_color_map[active_task.status]"
          >
            {{ $t(active_task.status) }}
          </HawkBadge>
        </div>
        <HawkMenu
          v-if="three_dot_items.length"
          class="!border-0"
          :items="three_dot_items"
          additional_trigger_classes="!ring-0"
          additional_dropdown_classes="!top-8 w-[200px]"
          :disabled="state.is_activity_details_loading"
        >
          <template #trigger>
            <HawkButton
              icon
              type="text"
              class="!h-10 !w-10"
            >
              <IconHawkDotsVertical class="text-gray-500" />
            </HawkButton>
          </template>
        </HawkMenu>
        <HawkButton
          id="activity-details-close-button"
          icon
          type="text"
          class="!h-10 !w-10"
          :disabled="state.is_activity_details_loading"
          @click="closeDetails"
        >
          <IconHawkXClose class="text-gray-500" />
        </HawkButton>
      </div>
    </div>
    <hr class="border-b border-gray-200 mt-2.5 -mx-6">
  </div>
  <div
    id="pm-activity-details-block"
    :key="`${active_task?.uid} ${current_lang}`"
    class="h-full bg-white scrollbar"
  >
    <div v-if="state.is_activity_details_loading" class="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full">
      <div class="z-10 text-gray-900">
        <HawkLoader />
      </div>
      <div class="absolute top-0 left-0 w-full h-full bg-white opacity-70" />
    </div>
    <div>
      <div v-show="activity_details_page === ''" class="px-6">
        <PmActivityDetailsOverview :dependencies="state.dependencies" />
        <hr class="my-3 -mx-6">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2 font-semibold text-gray-900 text-md">
            {{ $t('Additional details') }}
            <HawkLoader v-if="state.is_loading" container_class="" :height="4" :width="4" />
          </div>
          <HawkMenu
            :items="add_button_items"
            additional_trigger_classes="!ring-0"
            @open="state.is_add_button_open = true"
            @close="state.is_add_button_open = false"
          >
            <template #trigger>
              <HawkButton type="text">
                {{ $t('Add') }}
                <IconHawkChevronUp v-if="state.is_add_button_open" class="text-gray-700" />
                <IconHawkChevronDown v-else class="text-gray-700" />
              </HawkButton>
            </template>
          </HawkMenu>
        </div>
        <template v-if="is_empty_page">
          <div class="my-3 flex flex-col gap-5">
            <div v-if="is_schedule_editable" class="group flex gap-3 px-4 py-3 border border-gray-200 rounded-lg bg-gray-50 hover:bg-gray-100 hover:border-gray-500">
              <div>
                <IconHawkPmDependency class="w-8 h-8" />
              </div>
              <div class="w-full flex justify-between items-center">
                <div class="flex flex-col">
                  <div class="text-sm font-semibold text-gray-900">
                    {{ $t('Dependency') }}
                  </div>
                  <div class="text-sm font-normal text-gray-600">
                    {{ $t('Create connections between activities to indicate the order in which they should be completed.') }}
                  </div>
                </div>
                <div
                  class="flex items-center gap-2 p-2 invisible group-hover:visible text-sm font-semibold text-gray-600 ml-3 cursor-pointer whitespace-nowrap"
                  @click="onAddDependency"
                >
                  <IconHawkPlus />
                  {{ $t('Add dependency') }}
                </div>
              </div>
            </div>
            <div v-if="!active_task.is_backend_save_pending" class="group flex gap-3 px-4 py-3 border border-gray-200 rounded-lg bg-gray-50 hover:bg-gray-100 hover:border-gray-500">
              <div>
                <IconHawkPmAttachment class="w-8 h-8" />
              </div>
              <div class="w-full flex justify-between items-center">
                <div class="flex flex-col">
                  <div class="text-sm font-semibold text-gray-900">
                    {{ $t('Attachment') }}
                  </div>
                  <div class="text-sm font-normal text-gray-600">
                    {{ $t('Attach files or documents that provide additional context or information for the activity.') }}
                  </div>
                </div>
                <div
                  class="flex items-center gap-2 p-2 invisible group-hover:visible text-sm font-semibold text-gray-600 ml-3 cursor-pointer"
                  @click="onAddAttachmentsClicked"
                >
                  <IconHawkPlus />
                  {{ $t('Add attachment') }}
                </div>
              </div>
            </div>
            <div v-if="!is_schedule_editable" class="group flex gap-3 px-4 py-3 border border-gray-200 rounded-lg bg-gray-50 hover:bg-gray-100 hover:border-gray-500">
              <div>
                <IconHawkPmReference class="w-8 h-8" />
              </div>
              <div class="w-full flex justify-between items-center">
                <div class="flex flex-col">
                  <div class="text-sm font-semibold text-gray-900">
                    {{ $t('Reference') }}
                  </div>
                  <div class="text-sm font-normal text-gray-600">
                    {{ $t('Add references to tasks, files and forms that have been added in their respective modules.') }}
                  </div>
                </div>
                <div
                  class="flex items-center gap-2 p-2 invisible group-hover:visible text-sm font-semibold text-gray-600 ml-3 cursor-pointer"
                  @click="references_modal.open()"
                >
                  <IconHawkPlus />
                  {{ $t('Add reference') }}
                </div>
              </div>
            </div>
            <div v-if="!is_schedule_editable && [$g.config.types.task, $g.config.types.milestone].includes(active_task.type)" class="group flex gap-3 px-4 py-3 border border-gray-200 rounded-lg bg-gray-50 hover:bg-gray-100 hover:border-gray-500">
              <div>
                <IconHawkPmActivityTracking class="w-8 h-8" />
              </div>
              <div class="w-full flex justify-between items-center">
                <div class="flex flex-col">
                  <div class="text-sm font-semibold text-gray-900">
                    {{ $t('Activity tracking') }}
                  </div>
                  <div class="text-sm font-normal text-gray-600">
                    {{ $t('Track the time spent, cost spent, resources used in an activity to monitor the progress.') }}
                  </div>
                </div>
                <div
                  class="flex items-center gap-2 p-2 invisible group-hover:visible text-sm font-semibold text-gray-600 ml-3 cursor-pointer"
                  @click="onAddActivityTracking"
                >
                  <IconHawkPlus />
                  {{ $t('Add activity tracking') }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <PmDependenciesSection
            :dependencies="state.dependencies"
            @add-dependency="onAddDependency"
            @edit-dependency="onEditDependency"
          />
          <template v-if="!active_task.is_backend_save_pending">
            <PmAttachmentsSection
              :attachments="attachments"
              @add-attachment="onAddAttachmentsClicked"
            />
            <PmReferencesSection
              v-if="!is_schedule_editable"
              :is-loading="state.is_loading"
              @add-reference="references_modal.open()"
            />
            <PmActivityTrackingSection
              v-if="!is_schedule_editable"
              @add-tracking="onAddActivityTracking"
            />
          </template>
        </template>
        <template v-if="active_task.is_backend_save_pending">
          <p class="2xl:min-h-[calc(100vh-575px)] min-h-[calc(100vh-690px)] mb-10 text-sm text-gray-400 italic">
            {{ $t('Note: Please save your progress to be able to add attachments.') }}
          </p>
        </template>
        <template v-else>
          <PmCommentsSection :class="state.is_loading && !['row-comments', 'mentions-toast'].includes(triggered_by) ? 'hidden' : ''" />
          <div
            v-if="!is_schedule_editable || chat_store?.comments?.length"
            :class="!(state.is_loading && !['row-comments', 'mentions-toast'].includes(triggered_by)) ? 'hidden' : ''"
            class="pt-2 pb-2 pr-2 mt-2 -mb-2"
          >
            <div class="flex items-center gap-2 font-semibold text-gray-900 text-md">
              {{ $t('Comments') }}
              <HawkLoader container_class="" :height="4" :width="4" />
            </div>
          </div>
        </template>
      </div>
      <PmProgressHistory v-if="activity_details_page === 'progress-history'" />
    </div>
  </div>
</template>
