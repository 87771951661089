import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DOMPurify from 'dompurify';
import { cloneDeep, keyBy, startCase, uniq } from 'lodash-es';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { useChatStore } from '~/common/stores/chat.store.js';
import { useCommonStore } from '~/common/stores/common.store';
import { sleep } from '~/common/utils/common.utils';
import { getDefaultView } from '~/project-management/constants/pm-default-view';
import { properties_all, properties_for_dates_finish, properties_for_dates_start } from '~/project-management/constants/pm-properties';
import { useTasksStore } from '~/tasks/store/tasks.store';

dayjs.extend(customParseFormat);

export const useProjectManagementStore = defineStore('project_management', {
  state: () => ({
    $g: null,
    active_tab: 'gantt-chart',
    activity_details_page: '',
    schedule_pusher_channel: null,
    activity_pusher_channel: null,
    active_view: null,
    active_task_uid: null,
    selected_task_id: null,
    active_schedule: null,
    active_instance_id: null,
    is_searching: false,
    is_fullscreen: false,
    is_pm_loading: false,
    is_initializing: true,
    is_refresh_required: false,
    is_recalculation_enabled: true,
    is_mini_loading: false,
    is_resources_panel_loading: false,
    pm_loading_message: '',
    view_dirtiness: 0,
    schedule_dirtiness: false,
    datepicker_tippy: null,
    resources_tippy: null,
    hidden_focus_input: null,
    views: [],
    markers: [],
    schedules: [],
    references: [],
    automations: [],
    pm_comments: [],
    groups_cache: [],
    loaded_wbs_ids: [],
    triggered_by: null,
    pm_attachments: [],
    filtered_task_ids: [],
    last_task_props: {},
    resources_overloaded: {},
    auto_update_progress_cache: {},
    resource_mode: 'hours',
    // NOTE: dom_refs array
    flags: {
      add_surrogate: true,
      set_wbs_to_max: false,
      is_search_visible: false,
      hide_save_and_submit: true,
      is_task_being_dragged: false,
      expand_all_after_parse: false,
      use_last_task_props: false,
      is_activity_details_showing_more: false,
      resources_section_reload_count: 0,
      attachments_trigger_count: 0,
      activities_updated_count: 0,
    },
    search_config: {
      search_string: '',
      current_match_index: -1,
      match_count: -1,
      search_results: [], // contains the UIDs of the tasks that match the search
    },
  }),
  getters: {
    // This getter returns the data in a format consumable by DHTMLX Gantt
    active_schedule_data() {
      return {
        data: Object.values(this.active_schedule?.activities || {}),
        links: [...(this.active_schedule?.relations || [])],
      };
    },
    active_task() {
      return this.active_schedule?.activities?.[this.active_task_uid];
    },
    active_task_auto_progress_cache() {
      return this.auto_update_progress_cache[this.active_task_uid];
    },
    active_schedule_sync_history() {
      try {
        return this.active_schedule.sync_history.map((data, index) => {
          return {
            ...data,
            id: index + 1,
          };
        }).reverse();
      }
      catch {
        return [];
      }
    },
    active_schedule_calendars() {
      const calendars = this.active_schedule?.calendars || [];
      const result = [];

      for (const calendar of calendars) {
        result.push({
          ...calendar,
          worktime: {
            hours: [],
            days: Object.values(calendar.days).map(day => day.is_working ? day.working_hours : 0),
          },
        });
      }

      return result;
    },
    is_schedule_editable() {
      return !this.active_schedule?.is_published;
    },
    is_schedule_dynamic() {
      return this.active_schedule?.is_dynamic_loading;
    },
    active_calendar() {
      if (!this.active_schedule?.calendar_id)
        return false;
      return this.active_schedule_calendars
        ?.find?.(calendar => calendar.id === this.active_schedule?.calendar_id);
    },
    tippy_target() {
      return this.is_fullscreen ? document?.getElementById?.('pm-fullscreen-container') : document?.body;
    },
    comment_priority_status() {
      let has_unread_mentions = false;
      let has_unread_comments = false;
      this.active_schedule_data.data.forEach((activity) => {
        if (activity.comments?.has_unread_mentions)
          has_unread_mentions = true;
        if (activity.comments?.has_unread_comments)
          has_unread_comments = true;
      });

      if (has_unread_mentions)
        return 'unread_mentions';
      else if (has_unread_comments)
        return 'unread_comments';
      else return 'all_comments';
    },
    active_calendar_formatted() {
      if (!this.active_schedule.calendar_id) {
        return {
          selected_weekdays: [],
          working_hours: [],
        };
      }

      const selected_weekdays = [];
      let working_hours = [];
      const calendar = this.active_calendar;

      for (const day in calendar.days) {
        if (calendar.days[day].is_working) {
          selected_weekdays.push(day);
          if (!working_hours.length) {
            const wh = calendar.days[day].working_hours;
            for (const working_hour of wh) {
              const str = working_hour.replaceAll(':00', '');
              const split_numbers = str.split('-');
              const num_start = Number.parseInt(split_numbers[0], 10);
              const num_end = Number.parseInt(split_numbers[1], 10) || 24;
              const range = Array.from({ length: num_end - num_start }, (_, i) => i + num_start);
              working_hours = [...working_hours, ...range];
            }
          }
        }
      }

      return {
        selected_weekdays,
        working_hours,
      };
    },
  },
  actions: {
    async save_active_schedule(body) {
      try {
        const id = this.active_schedule.uid;
        const { data } = await this.$services.project_management.post({
          id,
          url: 'project-management/schedules',
          attribute: 'save',
          body,
        });
        return data;
      }
      catch (error) {
        logger.error(error);
        this.$toast({
          title: error?.data?.title,
          text: error?.data?.description,
          type: 'error',
          timeout: 4000,
        });
        return error;
      }
    },
    async publish_schedule(uid) {
      const id = uid ?? this.active_schedule.uid;
      const { data } = await this.$services.project_management.post({
        id,
        url: 'project-management/schedules',
        attribute: 'publish',
      });
      return data;
    },
    async unpublish_schedule(uid) {
      const id = uid ?? this.active_schedule.uid;
      const { data } = await this.$services.project_management.post({
        url: 'project-management/schedules',
        id,
        attribute: 'unpublish',
      });
      return data;
    },
    async create_blank_schedule(body) {
      try {
        const { data } = await this.$services.project_management.post({
          url: 'project-management/schedules',
          body,
        });
        return data.data[0];
      }
      catch (error) {
        logger.error(error);
        this.$toast({
          title: error?.data?.title,
          text: error?.data?.description,
          type: 'error',
          timeout: 4000,
        });
        return error;
      }
    },
    async set_schedules(query) {
      try {
        const { data } = await this.$services.project_management.getAll({
          attribute: 'schedules',
          query,
        });
        this.schedules = data?.data ?? [];
      }
      catch (error) {
        logger.error(error);
      }
    },
    async update_activity_backend(uid, body) {
      try {
        const { data } = await this.$services.project_management.post({
          url: 'project-management/schedules',
          id: this.active_schedule.uid,
          attribute: `activities/${uid}/update`,
          body,
        });
        return data;
      }
      catch (error) {
        this.$toast({
          title: error?.data?.title || 'Something went wrong',
          text: error?.data?.description || 'Please try again',
          type: 'error',
          timeout: 4000,
        });
        logger.error(error);
      }
    },
    async save_resources(added, updated, deleted) {
      if (added.length) {
        await this.$services.project_management.post({
          url: 'project-management/schedules',
          id: this.active_schedule.uid,
          attribute: 'resources/create',
          body: added,
        });
      }
      if (updated.length) {
        await this.$services.project_management.post({
          url: 'project-management/schedules',
          id: this.active_schedule.uid,
          attribute: 'resources/update',
          body: updated,
        });
      }
      if (deleted.length) {
        await this.$services.project_management.post({
          url: 'project-management/schedules',
          id: this.active_schedule.uid,
          attribute: 'resources/delete',
          body: deleted,
        });
      }

      await this.set_schedule_resources(this.active_schedule.uid);
    },
    async set_versions() {
      const { data } = await this.$services.project_management.get({
        url: 'project-management/schedules',
        id: this.active_schedule.uid,
        attribute: 'versions',
      });
      return data;
    },
    async create_version({ uid, body }) {
      try {
        const id = uid ?? this.active_schedule.uid;
        const { data } = await this.$services.project_management.post({
          url: 'project-management/schedules',
          id,
          attribute: 'versions',
          body,
        });
        return data;
      }
      catch (error) {
        logger.error(error);
        this.$toast(
          {
            title: error?.data?.title,
            text: error?.data?.description,
            type: 'error',
            timeout: 4000,
          },
        );
        return error;
      }
    },
    async update_version({ schedule_uid, version_id, body }) {
      const schedule_id = schedule_uid ?? this.active_schedule.uid;
      const { data } = await this.$services.project_management.patch({
        url: 'project-management/schedules',
        id: schedule_id,
        attribute: `versions/${version_id}`,
        body,
      });
      return data;
    },
    async delete_version({ schedule_uid, version_id }) {
      const schedule_id = schedule_uid ?? this.active_schedule.uid;
      const { data } = await this.$services.project_management.delete({
        url: 'project-management/schedules',
        id: schedule_id,
        attribute: `versions/${version_id}`,
      });
      return data;
    },
    async download_version({ schedule_uid, version_id }) {
      const schedule_id = schedule_uid ?? this.active_schedule.uid;
      const { data } = await this.$services.project_management.get({
        url: 'project-management/schedules',
        id: schedule_id,
        attribute: `versions/${version_id}/download`,
      });
      return data;
    },
    async configure_auto_sync({ schedule_uid, body }) {
      const id = schedule_uid ?? this.active_schedule.uid;
      const { data } = await this.$services.project_management.post({
        url: 'project-management/schedules',
        id,
        attribute: 'register-sync-trigger',
        body,
      });
      this.update_active_schedule(
        id,
        { sync_schedule: data.data.sync_schedule },
        false,
      );
    },
    async sync_activity_progress({ schedule_uid, body, signal }) {
      const id = schedule_uid ?? this.active_schedule.uid;
      const { data } = await this.$services.project_management.post({
        url: 'project-management/schedules',
        id,
        body,
        attribute: 'sync-progress',
        signal,
      });

      const promises = data?.data?.[0]?.activities?.map?.(payload => this.update_activity(payload, true));
      await Promise.allSettled(promises);
      this.$g.render();

      const { data: schedule_data } = await this.$services.project_management.get({
        url: 'project-management/schedules',
        id,
        query: { select: ['schedules'] },
      });
      this.update_active_schedule(
        id,
        schedule_data.data[0],
        false,
      );
      return data;
    },
    async delete_schedule(id) {
      await this.$services.project_management.delete({
        url: 'project-management/schedules',
        id,
      });
      this.schedules = this.schedules.filter(s => s.uid !== id);
    },
    async search_tasks(id_or_name, schedule_uid = null) {
      try {
        const { data } = await this.$services.project_management.get({
          id: schedule_uid ?? this.active_schedule.uid,
          url: 'project-management/schedules',
          attribute: 'dhtmlx',
          query: { id_or_name },
        });

        const activities = data.data;

        for (const task of activities) {
          this.preprocess_activity(task);
          // The gantt's copy is not updated here because this action is performed on a dynamic schedule and the store's copy is first updated and then the store's copy is used to update the gantt's copy using .parse()
          this.active_schedule.activities[task.uid] = task;
        }
      }
      catch (error) {
        logger.error(error);
      }
    },
    async set_children_tasks(parent_id = null, schedule_uid = null, fetch_only = false) {
      try {
        const { data } = await this.$services.project_management.get({
          id: schedule_uid ?? this.active_schedule.uid,
          url: 'project-management/schedules',
          attribute: 'dhtmlx',
          ...(parent_id ? { query: { parent_id } } : { query: { level: 2 } }),
        });

        if (fetch_only)
          return data;

        const activities = data.data;
        const newly_added_activities = [];

        for (const task of activities) {
          this.preprocess_activity(task);
          if (!this.active_schedule.activities[task.uid])
            newly_added_activities.push(task);
          // The gantt's copy is not updated here because this action is performed on a dynamic schedule and the store's copy is first updated and then the store's copy is used to update the gantt's copy using .parse()
          this.active_schedule.activities[task.uid] = task;
        }
        return newly_added_activities;
      }
      catch (error) {
        logger.error(error);
      }
    },
    async set_schedule_resources(schedule_uid) {
      const { data } = await this.$services.project_management.get({
        url: 'project-management/schedules',
        id: schedule_uid,
        attribute: 'resources',
      });
      // NOTE: maybe keyby
      this.active_schedule.resources = data.data;
    },
    async set_schedule(schedule_uid, $t) {
      const found = this.schedules.find(schedule => schedule.uid === schedule_uid);
      let is_dynamic_loading = null;

      if (found) {
        is_dynamic_loading = !!found?.is_dynamic_loading && !!found?.is_published;
        this.views = [getDefaultView($t, found), ...this.views];
      }
      else {
        const { data } = await this.$services.project_management.get({
          url: 'project-management/schedules',
          id: schedule_uid,
        });
        const schedule = data.data[0];
        is_dynamic_loading = !!schedule?.is_dynamic_loading && !!schedule?.is_published;
        this.views = [getDefaultView($t, schedule), ...this.views];
      }

      let select = [
        'versions',
        'calendars',
        'relations',
        'resources',
        'schedules',
        'suggestions',
        'resource_assignments',
        'trackings',
      ];

      if (!is_dynamic_loading)
        select.push('activities');

      select = select.join(',');

      try {
      // fetch the schedule with selected properties
        const { data } = await this.$services.project_management.get({
          url: 'project-management/schedules',
          id: schedule_uid,
          query: { select },
        });
        const schedule = data.data[0];

        if (!schedule.is_published) {
          this.set_active_view('__default');
        }
        else {
          const default_view = this.views.find(view => view.default);
          this.set_active_view(default_view?.uid ?? '__default');
        }

        if (is_dynamic_loading) {
          const data = await this.set_children_tasks(null, schedule_uid, true);
          schedule.activities = data.data;
        }

        if (!schedule.timezone)
          schedule.timezone = 'Atlantic/Reykjavik'; // Timezone with offset = UTC and no DST. Hence, safe to be compared with UTC. (Used across PM)
        // initialize markers and add the default ones
        const markers = [
          [
            dayjs(dayjs().tz(schedule.timezone).utc(true).startOf('day').toISOString().slice(0, -1)).tz(schedule.timezone).toDate(),
            'today',
            'Today',
            'today-marker',
            'today-marker',
          ],
          ...(
            schedule.data_date
              ? [
                  [
                    dayjs(dayjs(schedule.data_date).tz(schedule.timezone).utc(true).startOf('day').toISOString().slice(0, -1)).tz(schedule.timezone).toDate(),
                    'data-date',
                    'Data date',
                    'data-date-marker',
                    'data-date-marker',
                  ],
                ]
              : []),
          ...(
            schedule.deadline
              ? [
                  [
                    dayjs(dayjs(schedule.deadline).toISOString().slice(0, -1)).tz(schedule.timezone).toDate(),
                    'deadline',
                    'Deadline',
                    'deadline-marker',
                    'deadline-marker',
                  ],
                ]
              : []),
        ];

        // prepare the data to be used with DHTMLX Gantt

        for (const task of schedule.activities) {
          this.preprocess_activity(task, schedule.is_dynamic_loading && schedule.is_published);

        // if the type of a task is project, then add project-specific markers
        // if (task.type === 'PROJECT')
        //   markers.push(
        //     [task.start_date, `project_${task.uid}_start`, 'Project Start', 'project-start-marker'],
        //     [task.end_date, `project_${task.uid}_end`, 'Project End', 'project-end-marker'],
        //   );
        }

        this.set_markers(markers.map(marker =>
          ['start_date', 'name', 'text', 'css', 'id']
            .reduce((acc, cur, index) => {
              acc[cur] = marker[index];
              return acc;
            }, {}),
        ));

        schedule.activities = keyBy(schedule.activities, 'uid');

        // transform activities and markers and set the active_schedule data
        this.active_schedule = schedule;

        let activities_with_resources = this.active_schedule.resource_assignments.map(assignment => assignment.activity);
        activities_with_resources = uniq(activities_with_resources);
        activities_with_resources.forEach((activity_uid) => {
          const task = this.active_schedule.activities[activity_uid];
          if (task)
            this.add_resources_property(task);
        });

        const found = this.schedules?.find(s => s.uid === schedule.uid);
        if (!found)
          this.schedules.push(schedule);
      }
      catch (error) {
        logger.error(error);
      }
    },
    preprocess_activity(activity, is_dynamic_loading) {
      let retain_wbs_type = is_dynamic_loading;
      if (!retain_wbs_type) {
        retain_wbs_type = this.is_schedule_dynamic && !this.is_schedule_editable;
      }
      // ALWAYS NECESSARY
      activity.text = DOMPurify.sanitize(activity.text || activity.name, { ALLOWED_TAGS: [] });
      // The DHTMLX Gantt by default calculates the start_date and end_date of the tasks of the type 'PROJECT' based on the start_date and end_date of its children. However, if we use 'WBS' project type which is custom, the default behavior will no longer take place. Therefore, we convert 'WBS' types to 'PROJECT' just for feeding the gantt and internally we use _original_type for the actual type.
      activity._original_type = activity.type;

      activity.is_root = activity.type === 'PROJECT';

      if (activity.is_root)
        activity.weight = 1;

      if (activity.weight)
        activity.weight = Number.parseFloat(((activity.weight || 0) * 100).toFixed(2));

      if (!retain_wbs_type || this.loaded_wbs_ids.includes(activity.id))
        activity.type = activity.type === 'WBS' ? 'PROJECT' : activity.type;
      // Server-calculated duration can often differ from DHTMLX Gantt's calculation.
      // Since the .duration property is overwritten by Gantt, server_duration stores the server-calculated value.
      activity.server_duration = activity.duration;

      activity.duration = activity.planned_duration;
      activity.start_date = activity.planned_start;
      activity.end_date = activity.planned_finish;
      // Store custom fields as top-level properties
      const custom_field_values = (activity?.custom_field_values || {});
      for (const property in custom_field_values)
        activity[`custom_field_${property}`] = custom_field_values[property];

      // FOR NORMALIZATION
      properties_for_dates_start.forEach((property) => {
        if (activity[property] && dayjs.isDayjs(activity[property]))
          activity[property] = dayjs(activity[property]).toISOString();
        if (activity?.[property]?.split)
          activity[property] = dayjs(activity[property].split('T')[0], 'YYYY-MM-DD').startOf('day').toDate();
      });

      properties_for_dates_finish.forEach((property) => {
        if (activity[property] && dayjs.isDayjs(activity[property]))
          activity[property] = dayjs(activity[property]).toISOString();
        if (activity?.[property]?.split)
          activity[property] = dayjs(activity[property].split('T')[0], 'YYYY-MM-DD').add(1, 'day').startOf('day').toDate();
      });

      if (this.active_schedule)
        this.add_resources_property(activity);
    },
    add_resources_property(activity) {
      activity.resources = this.active_schedule.resource_assignments
        .filter(assignment => assignment.activity === activity.uid)
        .map(assignment => assignment.resource);
    },
    async set_views(schedule_uid) {
      try {
        // fetch the views stored on server against this schedule
        const { data } = await this.$services.project_management.getAll({
          attribute: `schedules/${schedule_uid}/views`,
        });

        const user_defined_views = (data?.data || []).filter(v => !v?.data?.configuration);

        // set the views array
        this.views = [...user_defined_views];
      }
      catch (error) {
        logger.error(error);
      }
    },
    set_active_view(view_uid = '__default', view_obj = null) {
      this.set_active_task_uid();
      if (view_obj) {
        this.active_view = view_obj;
      }
      else {
        const view = this.views.find(view => view.uid === view_uid);
        this.active_view = cloneDeep(view);
      }
      this.set_view_dirtiness(false);
    },
    async create_view(label, is_default = true) {
      function cleanupColumn(column) {
        const temp_column = { ...column };
        if (column.name === 'wbs')
          temp_column.label = 'WBS';
        delete temp_column.editor;
        delete temp_column.template;
        return temp_column;
      }

      const config = {
        ...this.active_view.data,
        grid_width: this.$g.config.grid_width,
        columns: this.$g.config.columns.filter(column => column.name !== 'select-columns').map(column => cleanupColumn(column)),
      };

      try {
        const payload = { ...config, label, schema: 'v2' };
        delete payload.uid;
        const { data } = await this.$services.project_management.post({
          attribute: `schedules/${this.active_schedule.uid}/views`,
          body: {
            default: is_default,
            data: payload,
          },
        });

        if (is_default === true) {
          this.views.forEach((item) => {
            item.default = false;
          });
        }

        const view = data?.data;
        if (!view)
          throw new Error('empty response');

        this.views = [...this.views, view];
        this.hidden_focus_input.focus();
        this.set_active_view(view.uid);
      }
      catch (error) {
        logger.error(error);
      }
    },
    async delete_view(view_uid) {
      let resolve = null;
      try {
        const data = await this.$services.project_management.delete({
          attribute: `/schedules/${this.active_schedule.uid}/views/${view_uid}`,
        });

        resolve = data;
      }
      catch (error) {
        logger.error(error);
        return resolve;
      }

      this.set_active_view('__default');
      this.views = this.views.filter(v => v.uid !== view_uid);

      return resolve;
    },
    async update_view(view) {
      try {
        this.hidden_focus_input.focus();
        view.data.grid_width = this.$g.config.grid_width;
        view.data.columns = this.$g.config.columns
          .map((column) => {
            if (column.name === 'wbs')
              column.label = 'WBS';
            return column;
          })
          .filter(column => column.name !== 'select-columns');
        await this.$services.project_management.patch({
          attribute: `schedules/${this.active_schedule.uid}/views/${view.uid}`,
          body: { data: view.data },
        });

        const index = this.views.findIndex(v => v.uid === view.uid);
        if (index !== -1)
          this.views[index] = view;
      }
      catch (error) {
        logger.error(error);
      }
    },
    async bulk_views_update(operation, fields_data) {
      let body = this.views;
      if (operation === 'patch') {
        body = body.map((view) => {
          view.data.columns = view.data.columns.map((column) => {
            if (column.name === `custom_field_${fields_data.field}`) {
              column.label = fields_data.new_name;
              column.name = `custom_field_${fields_data.new_name}`;
            }
            return column;
          });
          return view;
        });
      }
      else if (operation === 'delete') {
        body = body.map((view) => {
          view.data.columns = view.data.columns.filter(column => column.label !== fields_data.fields);
          return view;
        });
      }

      const default_view = body.find(view => view.uid === '__default');
      body = body.filter(view => view.uid !== '__default').map((view) => {
        return {
          view: view.uid,
          view_data: {
            data: view.data,
          },
        };
      });

      if (body.length) {
        const { data } = await this.$services.project_management.post({
          url: 'project-management/schedules',
          id: this.active_schedule.uid,
          attribute: 'views/update',
          body,
        });
        this.views = [default_view, ...data.data];
      }
    },
    async create_update_or_delete_custom_field({ type, schedule_uid, body }) {
      if (!['post', 'patch', 'delete'].includes(type))
        return;

      const id = schedule_uid ?? this.active_schedule.uid;
      const { data } = await this.$services.project_management[type]({
        id,
        url: 'project-management/schedules',
        attribute: 'custom-field',
        body,
      });

      if (['patch', 'delete'].includes(type))
        this.bulk_views_update(type, body);

      if (type === 'delete')
        return;

      if (body.field && body.new_name && body.field !== body.new_name) {
        this.update_column({
          old_name: body.field,
          new_name: body.new_name,
        });
      }
      else {
        // to force deeply nested fields like currency symbols to refresh
        const columns = cloneDeep(this.active_view.data.columns);
        this.active_view.data.columns = columns;
      }

      if (!data?.data?.custom_fields)
        return;

      this.update_active_schedule(id, { custom_fields: data.data.custom_fields }, false);
      return data;
    },
    async add_or_update_custom_field_value(body) {
      const id = this.active_schedule.uid;
      const { data } = await this.$services.project_management.post({
        id,
        url: 'project-management/schedules',
        attribute: 'activities/custom-field',
        body,
      });

      if (!data?.data?.length)
        return;

      const custom_fields = cloneDeep(this.active_schedule.custom_fields);
      for (const field in custom_fields) {
        const fields = data.data.map(a => a.custom_field_values[field]).filter(x => x);
        custom_fields[field].values.push(...fields);
      }
      this.update_active_schedule(id, { custom_fields }, false);

      const promises = data?.data?.map?.(payload => this.update_activity(payload, true));
      await Promise.allSettled(promises);
      this.$g.render();

      return data;
    },
    async clear_custom_field_value(body) {
      const id = this.active_schedule.uid;
      await this.$services.project_management.delete({
        id,
        url: 'project-management/schedules',
        attribute: 'activities/custom-field',
        body,
      });
      this.$g.render();
    },
    async update_activity_reference({ activity, body }) {
      if (!activity?.uid)
        return;

      const id = this.active_schedule.uid;
      const { data } = await this.$services.project_management.post({
        id,
        url: 'project-management/schedules',
        attribute: `activities/${activity.uid}/update`,
        body: {
          references: body,
        },
      });

      data?.data?.map?.(payload => this.update_activity(payload, true));
      this.$g.render();

      return data;
    },
    async update_activity_progress(
      {
        progress,
        data_date,
        actual_start_date,
        actual_finish_date,
      },
    ) {
      const body = { progress, data_date };

      if (this.active_task.progress === 0 && progress < 1) {
        body.actual_start = actual_start_date;
      }
      else if (this.active_task.progress > 0 && progress === 1) {
        body.actual_finish = actual_finish_date;
      }
      else if (this.active_task.progress === 0 && progress === 1) {
        body.actual_start = actual_start_date;
        body.actual_finish = actual_finish_date;
      }

      const data = await this.update_activity_backend(this.active_task.uid, body);

      this.$g.batchUpdate(async () => {
        for (const activity of data.data) {
          await sleep(100);
          this.update_activity(activity, true, properties_all);
        }
      });
      this.$g.render();

      return data;
    },
    async refetch_activity(activity_uid, properties_to_update = null) {
      if (!activity_uid)
        return;

      const { data } = await this.$services.project_management.get({
        url: 'project-management/schedules',
        id: this.active_schedule.uid,
        attribute: `activities/${activity_uid}`,
      });

      const update_data = data.data;

      this.update_activity(update_data, true, properties_to_update);
    },
    update_activity(payload, preprocess = false, properties_to_update = null) {
      if (!payload.uid)
        return;

      if (preprocess)
        this.preprocess_activity(payload);

      const selective_payload = { uid: payload.uid };
      let selective_properties = properties_to_update;

      const custom_field_values = (payload?.custom_field_values || {});
      for (const property in custom_field_values)
        properties_all.push(`custom_field_${property}`);

      if (!properties_to_update)
        selective_properties = properties_all;

      Object.keys(payload).forEach((property) => {
        if (selective_properties?.includes?.(property)) {
          selective_payload[property] = payload[property];

          if (property === 'data_date') {
            const existing_date = this.$g.getMarker('data-date-marker').start_date;
            const new_date = dayjs(dayjs(selective_payload[property]).tz(this.active_schedule.timezone).utc(true).startOf('day').toISOString().slice(0, -1)).tz(this.active_schedule.timezone).toDate();

            if (dayjs(new_date).isAfter(existing_date, 'day')) {
              this.active_schedule.data_date = selective_payload[property];
              this.$g.getMarker('data-date-marker').start_date = new_date;
              this.$g.updateMarker('data-date-marker');
            }
          }
        }
      });

      const updated_activity = cloneDeep({
        ...this.active_schedule.activities[payload.uid],
        ...selective_payload,
      });

      Object.keys(updated_activity).forEach((key) => {
        if (!properties_all.includes(key)) {
          delete updated_activity[key];
        }
      });

      this.active_schedule.activities[payload.uid] = updated_activity;
      const task = this.$g.getTask(updated_activity.id);

      Object.keys(selective_payload).forEach((key) => {
        task[key] = updated_activity[key];
      });
      this.$g.updateTask(updated_activity.id);
    },
    async set_activity_auto_progress(body) {
      const { data } = await this.$services.project_management.post({
        id: this.active_schedule.uid,
        url: 'project-management/schedules',
        attribute: `activities/${this.active_task.uid}/sync-progress`,
        body,
      });

      this.update_activity({
        uid: this.active_task.uid,
        auto_progress_sync: data.data,
      }, false, ['auto_progress_sync']);
      this.$g.render();

      return data;
    },
    async remove_activity_auto_progress_link() {
      const { data } = await this.$services.project_management.delete({
        id: this.active_schedule.uid,
        url: 'project-management/schedules',
        attribute: `activities/${this.active_task.uid}/sync-progress`,
      });

      if (data?.data?.length) {
        this.$g.batchUpdate(async () => {
          for (const activity of data.data[0].activities) {
            await sleep(100);
            this.update_activity(activity, true, properties_all);
          }
        });
        this.$g.render();
      }

      return data;
    },
    set_view_dirtiness(bool = true) {
      // NOTE: refactor this to be just a boolean and get rid of the action (mutate state directly)
      this.view_dirtiness = bool ? this.view_dirtiness + 1 : 0;
    },
    set_schedule_dirtiness(bool = true) {
      this.schedule_dirtiness = bool;
      if (bool)
        this.flags.hide_save_and_submit = false;
    },
    set_search_config(val) {
      this.search_config = {
        ...this.search_config,
        ...val,
      };
    },
    modify_filter(payload) {
      const filters = { ...(this.active_view?.filters ?? {}), ...payload };
      this.active_view.data.filters = filters;
    },
    set_markers(payload) {
      this.markers = [...payload];
    },
    set_active_task_uid(uid = null, delay = 250) {
      this.triggered_by = null;
      const task = this.active_schedule?.activities?.[uid];
      if (this.active_task_uid === uid) {
        if (uid && this.$g.getSelectedId() !== task.id)
          this.selected_task_id = task.id;
        return;
      }

      this.active_task_uid = uid;
      this.$router.push(
        {
          ...this.$router.currentRoute.value,
          query: { ...(uid && { activity: uid }) },
        },
      );

      if (!uid || !task) {
        this.selected_task_id = null;
        setTimeout(() => {
          this.$g.render();
        }, 0);
        return;
      }

      this.selected_task_id = task.id;
      setTimeout(() => {
        this.$g.showTask(task.id);
      }, delay); // the delay ensure the resize operation (due to activity details popping up) has finished before it is brought into view
    },
    async view_activity() {
      await this.$services.project_management.get({
        url: 'project-management/schedules',
        id: this.active_schedule.uid,
        attribute: `activities/${this.active_task_uid}/view`,
      });
    },
    modify_config(payload) {
      if (this.active_view.data[payload.key] !== payload.value)
        this.active_view.data[payload.key] = payload.value;
    },
    toggle_config(keys = []) {
      for (const key of keys)
        this.active_view.data[key] = !this.active_view[key];
    },
    set_is_fullscreen(bool) {
      this.is_fullscreen = bool;
    },
    set_all_tasks_open_state(bool) {
      this.$g.eachTask((task) => {
        task.$open = bool;
      });
      this.$g.render();
    },
    set_active_date_range(val) {
      this.active_view.data.active_date_range = {
        ...this.active_view.data.active_date_range,
        ...val,
      };
    },
    async create_schedule(body) {
      try {
        const { data } = await this.$services.project_management.post({
          url: 'project-management/schedules',
          body,
        });
        return data.data[0];
      }
      catch (error) {
        logger.error(error);
        if (error?.title !== 'Invalid timeline') {
          this.$toast({
            title: error?.data?.title,
            text: error?.data?.description,
            type: 'error',
            timeout: 4000,
          });
        }
        return error;
      }
    },
    async update_active_schedule(id, body, sync = false) {
      const schedule = cloneDeep({ ...this.active_schedule, ...body });

      if (this.active_schedule?.uid === id)
        this.active_schedule = schedule;

      if (!sync)
        return;

      const { data } = await this.$services.project_management.patch({
        url: 'project-management/schedules',
        id,
        body,
      });
      return data;
    },
    update_column({ old_name, new_name }) {
      this.$g.eachTask((task) => {
        if (task.type === this.$g.config.types.surrogate)
          return;
        const activity = { ...this.active_schedule.activities[task.uid] };

        // update gantt's copy of the task
        task.custom_field_values[new_name] = activity.custom_field_values[old_name];
        // update store's copy of the activity
        activity.custom_field_values[new_name] = activity.custom_field_values[old_name];

        delete task.custom_field_values[old_name];
        delete activity.custom_field_values[old_name];

        // By just looking at the code, this shouldn't be needed as the gantt's and store's copies are already assigned.
        this.active_schedule.activities[task.uid] = activity;
      });

      // const columns = [...this.active_view.data.columns];
      // const found = columns.find(column => column.label === old_name);
      // if (found) {
      //   found.label = new_name;
      //   found.name = `custom_field_${new_name}`;
      //   this.active_view.data.columns = columns;
      // }

      this.$g.render();
    },
    async get_activity_logs(activity_uid) {
      if (!activity_uid)
        return;

      try {
        const { data } = await this.$services.project_management.get({
          url: 'project-management/schedules',
          id: this.active_schedule.uid,
          attribute: `activities/${activity_uid}/logs`,
          activity_uid,
          query: { type: 'PROGRESS_UPDATE' },
        });

        return data?.data || [];
      }
      catch (error) {
        logger.error(error);
        return null;
      }
    },
    async get_activity_progress_history(activity_uid) {
      if (!activity_uid)
        return;

      try {
        const { data } = await this.$services.project_management.get({
          url: 'project-management/schedules',
          id: this.active_schedule.uid,
          attribute: `activities/${activity_uid}/progress-history`,
          activity_uid,
        });

        return data?.data || [];
      }
      catch (error) {
        logger.error(error);
        return null;
      }
    },
    async get_activity_field_history(activity_uid) {
      if (!activity_uid)
        return;

      try {
        const { data } = await this.$services.project_management.get({
          url: 'project-management/schedules',
          id: this.active_schedule.uid,
          attribute: `activities/${activity_uid}/field-history`,
          activity_uid,
        });

        return data?.data || [];
      }
      catch (error) {
        logger.error(error);
        return null;
      }
    },
    async fetch_comments(all_channels) {
      const chat_store = useChatStore();

      let all_messages = [];
      const all_channel_uids = all_channels.map(item => `pm-${item.uid}`);
      const channels = await chat_store.chat?.client?.queryChannels?.({ id: { $in: all_channel_uids } });
      if (Array.isArray(channels)) {
        for (const channel of channels) {
          await channel.watch();
          let { messages } = await channel.query({ messages: {} });
          if (messages.length > 0) {
            const current_channel = all_channels.find(item => item.uid === channel.id.replace('pm-', ''));
            const allMessages = await Promise.all(messages.map(async (current_comment) => {
              const replies = await channel.getReplies(current_comment.id);
              return [
                current_comment,
                ...replies.messages,
              ];
            }));

            messages = allMessages.flat();

            let recent_message = null;
            messages.forEach((message) => {
              if (!recent_message || new Date(message.updated_at) >= new Date(recent_message.updated_at))
                recent_message = message;
            });

            all_messages = all_messages.concat({
              uid: current_channel.uid,
              id: current_channel.id,
              recent_message,
              all_messages: messages,
            });
          }
        }
      }
      return all_messages;
    },
    async set_pm_attachments(append_attachments = false, activities = []) {
      this.is_mini_loading = true;
      if (!activities.length)
        activities = Object.values(this.active_schedule.activities);

      if (!append_attachments)
        this.pm_attachments = [];
      activities.forEach((activity) => {
        const current_task = this.$g?.getTask?.(activity.id);
        activity.attachments?.forEach?.((attachment) => {
          if (current_task) {
            this.pm_attachments.push({
              ...attachment,
              wbs: this.$g.getWBSCode(current_task),
              task_uid: activity.uid,
              task_id: activity.id,
            });
          }
        });
      });

      this.$g?.render?.();
      this.is_mini_loading = false;
    },
    async set_references($t) {
      try {
        const { data } = await this.$services.project_management.get({
          id: this.active_schedule.uid,
          url: 'project-management/schedules',
          attribute: `activities/${this.active_task.uid}/references`,
        });
        this.references = data?.data;

        const common_store = useCommonStore();
        const task_store = useTasksStore();

        this.references = this.references?.map?.((res) => {
          let status = res?.status?.name;
          let color = res?.status?.color;
          let bg_color = '';

          if (res?.type === 'TASK') {
            const task_status_colors = {
              1: '#B54708',
              2: '#004EEB',
              3: '#027A48',
              4: '#344054',
              5: '#B42318',
            };
            const task_status_background_colors = {
              1: '#FFFAEB',
              2: '#EFF8FF',
              3: '#ECFDF3',
              4: '#F2F4F7',
              5: '#FEF3F2',
            };
            const status_object = task_store.status_values.find(item => item.value === res?.status?.id);
            status = status_object.label;
            color = task_status_colors[res?.status?.id];
            bg_color = task_status_background_colors[res?.status?.id];
          }
          const result = {
            ...res,
            name: res.name,
            tag: startCase(common_store.get_category(res?.category)?.name),
            uid: res.uid,
            date: res.due_date,
            status,
            color,
            type: res.type,
          };

          if (res.type === 'TASK')
            result.bg_color = bg_color;

          return result;
        });
      }
      catch (error) {
        this.$toast({
          text: $t('Please try again later'),
          type: 'error',
          title: $t('Unable to fetch references'),
          timeout: 2000,
        });
        logger.error(error);
      }
    },
    async create_tracking(body, activity_uid) {
      const { data } = await this.$services.project_management.post({
        url: 'project-management/schedules',
        id: this.active_schedule.uid,
        attribute: `activities/${activity_uid}/track`,
        body,
      });
      return data.data;
    },
    update_resource_assignments(all_resources, active_task_uid) {
      all_resources.forEach((assignee) => {
        const assignment_index = this.active_schedule.resource_assignments.findIndex(
          assignment => assignment.activity === active_task_uid && assignment.resource === assignee.uid,
        );

        if (assignment_index === -1) {
          this.active_schedule.resource_assignments.push({
            activity: active_task_uid,
            resource: assignee.uid,
            units_required: assignee.work || 0,
          });
        }
        else if (assignee.work && this.active_schedule.resource_assignments[assignment_index].units_required !== assignee.work) {
          this.active_schedule.resource_assignments[assignment_index].units_required = assignee.work;
        }
      });

      this.active_schedule.resource_assignments = this.active_schedule.resource_assignments.filter(
        (assignment) => {
          if (assignment.activity === active_task_uid)
            return all_resources.some(assignee => assignment.resource === assignee.uid);
          else
            return true;
        },
      );
    },
    set_gantt_active_calendar() {
      for (const day of [0, 1, 2, 3, 4, 5, 6])
        this.$g.unsetWorkTime({ day, hours: false });

      if (this.active_schedule.calendar_id) {
        const calendar = this.active_calendar;
        this.$g.deleteCalendar(calendar.id);
        this.$g.addCalendar(calendar);
        const off_day_list = [];
        for (const [index, day] of calendar.worktime.days.entries()) {
          const wt = { day: index, hours: day.length ? day : false };
          if (wt.hours?.length)
            this.$g.setWorkTime(wt);
          else
            off_day_list.push(wt);
        }
        off_day_list.forEach((day) => {
          this.$g.setWorkTime(day);
        });
      }
    },
    reload_data(recalculate) {
      window.SH_DEBUG && logger.info(`[DEBUG] pm-gantt-editable.vue\nreloadData called with recalculate = ${recalculate}`);
      this.is_recalculation_enabled = recalculate;
      this.$g.clearAll();

      this.$g.init(document.getElementById(this.active_instance_id));
      this.$g.parse(this.active_schedule_data);
      this.flags.resources_section_reload_count++;
    },
    async set_automations($t) {
      try {
        const { data } = await this.$services.project_management.get({
          url: 'project-management/schedules',
          id: this.active_schedule.uid,
          attribute: 'automations/list',
          query: {
            activity_uid: this.active_task_uid,
          },
        });
        this.automations = data.data;
      }
      catch (error) {
        this.$toast({
          text: $t('Please try again later'),
          type: 'error',
          title: $t('Unable to fetch automations'),
          timeout: 2000,
        });
        logger.error(error);
      }
    },
    async create_automation(body) {
      const { data } = await this.$services.project_management.post({
        url: 'project-management/schedules',
        id: this.active_schedule.uid,
        attribute: 'automations/create',
        body,
      });
      this.automations.push(data.data);
      return data.data;
    },
    async delete_automation(body) {
      await this.$services.project_management.post({
        url: 'project-management/schedules',
        id: this.active_schedule.uid,
        attribute: 'automations/delete',
        body,
      });
    },
    handle_subtask_addition(parent_task) {
      if (parent_task?.resources?.length) {
        parent_task.resources = [];
        this.active_schedule.resource_assignments = this.active_schedule.resource_assignments.filter(resource_assignment => resource_assignment.activity !== parent_task.uid);
      }
    },
    change_activity_id(old_id, new_id) {
      this.filtered_task_ids = this.filtered_task_ids.map(id => (id === old_id ? new_id : id));
      this.$g.changeTaskId(old_id, new_id);
      this.$g.render();
    },
    async save_editable_schedule(properties, $t) {
      if (!this.active_schedule.is_editable)
        return;
      const activities = [];
      const activity_ids = [];
      let prevent_save = false;
      let root_project_count = 0;
      this.$g.eachTask((task) => {
        task.is_backend_save_pending = false;
        if ([
          this.$g.config.types.project,
          this.$g.config.types.task,
          this.$g.config.types.milestone,
        ].includes(task.type)) {
          if (activity_ids.includes(task.id))
            prevent_save = true;
          if (task.type === this.$g.config.types.project && task.is_root)
            root_project_count++;
          activity_ids.push(task.id);
          activities.push({
            ...task,
            name: task.text,
            weight: task.weight ? (task.weight || 0) / 100 : task.weight,
            planned_start: dayjs(task.start_date).format('YYYY-MM-DD'),
            planned_finish: !dayjs(task.start_date).isSame(dayjs(task.end_date), 'day') ? dayjs(task.end_date).subtract(1, 'day').format('YYYY-MM-DD') : dayjs(task.end_date).format('YYYY-MM-DD'),
            planned_duration: task.duration,
            is_critical: task.progress !== 1 && (this.$g.isCriticalTask(task) || this.active_schedule.deadline ? dayjs(task.end_date).isAfter(this.active_schedule.deadline) : false),
            free_slack: this.$g.getFreeSlack(task),
            total_slack: this.$g.getTotalSlack(task),
            type: (task.type === this.$g.config.types.project && !task.is_root)
              ? this.$g.config.types.wbs
              : task.type,
            resource_assignments: this.active_schedule.resource_assignments.filter(resource_assignment => resource_assignment.activity === task.uid),
          });
        }
      });

      if (prevent_save) {
        this.$toast({
          title: $t('Unable to save the schedule'),
          text: $t('Duplicate activity IDs are present'),
          type: 'error',
          timeout: 4000,
        });
        return;
      }
      if (root_project_count > 1) {
        this.$toast({
          title: $t('Unable to save the schedule'),
          type: 'error',
          timeout: 4000,
        });
        return;
      }

      await this.save_active_schedule({
        activities,
        ...properties,
      });
      this.set_schedule_dirtiness(false);
    },
    async bulk_fetch_activities(activity_uids) {
      const { data } = await this.$services.project_management.post({
        url: 'project-management/schedules',
        id: this.active_schedule.uid,
        attribute: 'activities/details',
        body: {
          activities: activity_uids,
        },
      });
      return data.data;
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useProjectManagementStore, import.meta.hot));
