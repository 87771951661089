<!-- eslint-disable vue/prop-name-casing -->
<script setup>
// --------------------------------- Imports -------------------------------- //
import { cloneDeep, isEqual } from 'lodash-es';
import { nextTick } from 'vue';
import { directive as VTippy } from 'vue-tippy';
import { getUserFullName, stringToNumber } from '~/common/utils/common.utils';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  task: {
    type: Object,
    default: () => ({}),
  },
  assignees: {
    type: Array,
    default: () => [],
  },
  members: {
    type: Array,
    default: () => [],
  },
  teams: {
    type: Array,
    default: () => [],
  },
  active_schedule: {
    type: Object,
    default: () => ({}),
  },
  active_calendar_formatted: {
    type: Array,
    default: () => [],
  },
  resource_assignments: {
    type: Array,
    default: () => [],
  },
  // resources_overloaded: {
  //   type: Object,
  //   required: true,
  // },
  // on_calculate_overload: {
  //   required: true,
  // },
  on_close: {
    required: true,
  },
  on_save: {
    required: true,
  },
  is_schedule_editable: {
    type: Boolean,
    default: false,
  },
  translate: {
    type: Function,
    required: true,
  },
  view_only: {
    type: Boolean,
    default: false,
  },
  tippy_target: {
    required: true,
  },
});
// ---------------------------------- Emits --------------------------------- //

// --------------------------------- Injects -------------------------------- //

// ----------------------- Variables - Pinia - consts ----------------------- //

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //

// ------------------------ Variables - Local - refs ------------------------ //

// ---------------------- Variables - Local - reactives --------------------- //
const state = reactive({
  initial_state: {},
  is_initializing: true,
  assignees: [],
  members: [],
  custom_resources: [],
  work: [],
  checkboxes: [],
  is_editing: false,
  search: '',
  is_saving: false,
  initial_blur: true,
  last_blurred: null,
  all_members: [],
  all_custom_resources: [],
  selected_members: [],
  selected_custom_resources: [],
  members_default_idx: [],
  custom_resources_default_idx: [],
  filtered_members: [],
  filtered_custom_resources: [],
  member_multiselect_refresh: 0,
  custom_resource_multiselect_refresh: 0,
  // resources_overloaded: props.resources_overloaded,
});

// --------------------------- Computed properties -------------------------- //
const computed_members = computed(() => {
  if (state.is_editing)
    return state.all_members;
  else return state.members;
});

const computed_custom_resources = computed(() => {
  // all the available custom resources
  if (state.is_editing)
    return state.all_custom_resources;
  else return state.custom_resources;
});

const total_planned = computed(() => {
  let total = 0;
  computed_members.value.forEach((member, index) => {
    if (!state.is_editing || state.checkboxes[index])
      total += computeResourcePlannedCost(member, index);
  });
  computed_custom_resources.value.forEach((custom_resource, index) => {
    if (!state.is_editing || state.checkboxes[computed_members.value.length + index])
      total += computeResourcePlannedCost(custom_resource, computed_members.value.length + index);
  });
  return total;
});

const total_actual = computed(() => {
  let total = 0;
  computed_members.value.forEach((member) => {
    total += computeResourceActualCost(member);
  });
  computed_custom_resources.value.forEach((custom_resource) => {
    total += computeResourceActualCost(custom_resource);
  });
  return total;
});
// -------------------------------- Functions ------------------------------- //
function onUpdateResources() {
  state.initial_blur = false;
  state.is_editing = true;
}

function handlePaste(event) {
  const paste = (event.clipboardData || window.clipboardData).getData('text');
  if (Number.parseFloat(paste) < 0)
    event.preventDefault();
}

function preventKeydown(e) {
  if (e.keyCode === 189 || e.keyCode === 187 || e.keyCode === 69)
    e.preventDefault();
}

function keyUp(e, index) {
  if (Number.parseFloat(e.target.value) > 0) {
    activateCheckboxIndex(true, index);
  }
  else {
    activateCheckboxIndex(false, index);
  }
}

async function onClose() {
  if (state.is_editing && props.assignees.length) {
    state.is_editing = false;
    return;
  }

  props?.on_close?.();
}

async function onSave() {
  state.is_saving = true;
  let assignees_list = [...state.all_members, ...state.all_custom_resources];
  assignees_list = assignees_list.map((item, index) => {
    return {
      ...item,
      work: state.work[index],
    };
  }).filter((_item, index) => {
    return state.checkboxes[index];
  });

  const final_state = assignees_list.reduce((acc, item) => {
    acc[item.uid] = item.work;
    return acc;
  }, {});

  const has_changed = !isEqual(state.initial_state, final_state);
  if (has_changed) {
    setTimeout(async () => {
      await props.on_save(assignees_list);
      props.on_close();
      state.is_saving = false;
      state.is_editing = false;
    }, props.is_schedule_editable ? 100 : 0);
  }
  else {
    props.on_close();
    state.is_saving = false;
    state.is_editing = false;
  }
}

function truncate(str, length = 20, ellipses = '...') {
  if (!str)
    return '';

  const _str = String(str);

  if (_str.length <= length)
    return str;

  return _str.slice(0, length) + ellipses;
}

function computeResourcePlannedCost(resource, index) {
  const activity = props.task;

  if (resource.cost_type === 'per_hour')
    return (state.work[index] || 0) * (resource.cost || 0) * (activity.duration || 0);
  if (resource.cost_type === 'per_item')
    return (state.work[index] || 0) * (resource.cost || 0);
  return state.work[index] || 0;
}

function computeResourceActualCost(resource) {
  const resource_assignment = props.resource_assignments.find(item => item.resource === resource.uid && item.activity === props.task.uid);
  return resource_assignment?.cost_utilized || 0;
}

// function onCalculateOverload() {
//   let assignees_list = [...state.all_members, ...state.all_custom_resources];
//   assignees_list = assignees_list.map((item, index) => {
//     return {
//       ...item,
//       work: state.work[index],
//     };
//   }).filter((_item, index) => {
//     return state.checkboxes[index];
//   });
//   // state.resources_overloaded = props.on_calculate_overload(assignees_list);
// }

async function onBlur(e, _index, _resource, _work) {
  if (!state.last_blurred && state.initial_blur) {
    state.initial_blur = false;
    await nextTick();
    e.target.focus();
  }
  state.last_blurred = e.target.id;
  // if (resource) {
  //   let assignees_list = [resource];
  //   assignees_list = assignees_list.map((item) => {
  //     return {
  //       ...item,
  //       work,
  //     };
  //   }).filter((_item) => {
  //     return state.checkboxes[index];
  //   });
  //   // state.resources_overloaded = props.on_calculate_overload(assignees_list, false);
  // }
}

function activateCheckboxIndex(e, index, resource) {
  if (resource?.cost_type === 'per_hour') {
    if (e)
      state.work[index] = props.active_calendar_formatted?.working_hours?.length ? props.active_calendar_formatted.working_hours.length : 8;
    else
      state.work[index] = 0;
  }
  state.checkboxes[index] = e;
  // if (resource) {
  //   let assignees_list = [resource];
  //   assignees_list = assignees_list.map((item, index) => {
  //     return {
  //       ...item,
  //       work: state.work[index],
  //     };
  //   }).filter((_item, index) => {
  //     return state.checkboxes[index];
  //   });
  //   // state.resources_overloaded = props.on_calculate_overload(assignees_list, false);
  // }
}

function getCostString(cost) {
  return `${props.active_schedule?.currency?.symbol}${new Intl.NumberFormat('en-US').format(cost)}`;
}

// -------------------------------- Watchers -------------------------------- //
watch(() => [props.assignees, state.is_editing], () => {
  if (!props.assignees?.length)
    state.is_editing = true;
  state.all_members = cloneDeep(props.active_schedule.resources).filter(member => member.type === 'member');
  state.all_custom_resources = cloneDeep(props.active_schedule.resources).filter(member => member.type === 'custom');
  state.assignees = props.assignees;
  state.members = props.assignees.filter(item => item.type === 'member');
  state.custom_resources = props.assignees.filter(item => item.type === 'custom');
  state.work = [];
  state.work.push(...computed_members.value.map((resource) => {
    const resource_assignment = props.resource_assignments.find(item => item.resource === resource.uid && item.activity === props.task.uid);
    return resource_assignment?.units_required || 0;
  }));
  state.work.push(...computed_custom_resources.value.map((resource) => {
    const resource_assignment = props.resource_assignments.find(item => item.resource === resource.uid && item.activity === props.task.uid);
    return resource_assignment?.units_required || 0;
  }));

  if (state.is_initializing) {
    state.is_initializing = false;
    state.initial_state = props.assignees.reduce((acc, resource) => {
      const resource_assignment = props.resource_assignments.find(item => item.resource === resource.uid && item.activity === props.task.uid);
      acc[resource.uid] = resource_assignment?.units_required || 0;
      return acc;
    }, {});
  }
  if (state.is_editing) {
    state.checkboxes.push(...state.all_members.map((resource) => {
      return !!state.members.find(item => item.uid === resource.uid);
    }));
    state.checkboxes.push(...state.all_custom_resources.map((resource) => {
      return !!state.custom_resources.find(item => item.uid === resource.uid);
    }));
  }
}, { immediate: true });

watch(() => [state.search, state.is_editing], () => {
  const all_member_uids = computed_members.value.map(member => member.external_id);
  state.filtered_members = props.members.filter((prop_member) => {
    return all_member_uids.includes(prop_member.uid) && prop_member.label.toLowerCase().includes(state.search.toLowerCase());
  }).map(member => member.uid);
  state.filtered_custom_resources = computed_custom_resources.value.filter((resource) => {
    return resource.name.toLowerCase().includes(state.search.toLowerCase());
  }).map(custom_resource => custom_resource.uid);
}, { immediate: true });

// ----------------------------- Lifecycle Hooks ---------------------------- //
</script>

<template>
  <div
    id="resources-editor"
    :class="props.active_schedule.track_costs ? 'w-[645px]' : 'w-[400px]'"
  >
    <div class="flex h-[52px] -mt-1 px-2 items-center">
      <IconHawkSearchLg class="h-5 w-5 text-gray-600 mr-2" aria-hidden="true" />
      <input
        id="search"
        v-model="state.search"
        type="text"
        class="w-full text-sm font-regular text-gray-600"
        :placeholder="props.translate('Search resources')"
        @blur="onBlur"
      >
    </div>
    <hr class="-ml-1">
    <div class="p-4">
      <template v-if="state.filtered_members.length + state.filtered_custom_resources.length">
        <div class="-m-4 p-4 max-h-[200px] overflow-y-scroll scrollbar">
          <div v-if="state.filtered_members.length" class="grid gap-x-3 mb-2" :class="props.active_schedule.track_costs ? 'grid-cols-9' : 'grid-cols-8'">
            <div
              class="text-sm font-normal text-gray-600"
              :class="[
                state.is_editing ? 'ml-[26px]' : '',
                props.active_schedule.track_costs ? 'col-span-3' : 'col-span-6',
              ]"
            >
              {{ props.translate('Members') }}
            </div>
            <div class="col-span-2 text-sm font-normal text-gray-600">
              {{ props.translate('Work') }}
            </div>
            <div
              v-if="props.active_schedule.track_costs"
              class="col-span-2 text-sm font-normal text-gray-600"
            >
              {{ props.translate('Planned') }}
            </div>
            <div
              v-if="props.active_schedule.track_costs"
              class="col-span-2 text-sm font-normal text-gray-600"
            >
              {{ props.translate('Actual') }}
            </div>
          </div>
          <template
            v-for="(member, index) in computed_members"
            :key="index"
          >
            <div
              v-if="state.filtered_members.includes(member.external_id)"
              class="grid gap-x-3 py-2 pl-2 -ml-2 rounded-lg group hover:bg-gray-50"
              :class="{
                'opacity-40': !state.checkboxes[index] && state.is_editing,
                'grid-cols-9': props.active_schedule.track_costs,
                'grid-cols-8': !props.active_schedule.track_costs,
              }"
            >
              <div
                class="flex items-center text-sm font-normal text-gray-900 cursor-pointer mr-2"
                :class="[
                  props.active_schedule.track_costs ? 'col-span-3' : 'col-span-6',
                ]"
                @click="activateCheckboxIndex(!state.checkboxes[index], index, member)"
              >
                <HawkCheckbox
                  v-if="state.is_editing"
                  :model-value="state.checkboxes[index]"
                />
                <IconIcOutlineGroups
                  v-if="member.team"
                  class="w-6 h-6 text-primary-500"
                />
                <img
                  v-else-if="props.members.find(prop_member => prop_member.uid === member.external_id).display_picture"
                  alt="dp"
                  :src="props.members.find(prop_member => prop_member.uid === member.external_id).display_picture"
                  class="inline w-6 h-6 rounded-full"
                >
                <!-- NOTE: too much logic in the template -->
                <span
                  v-else
                  class="inline-flex items-center justify-center w-6 h-6 p-1 leading-none text-white rounded-full"
                  :style="`background-color: ${stringToNumber(getUserFullName(props.members.find(prop_member => prop_member.uid === member.external_id)))}`"
                >
                  {{ props.members.find(prop_member => prop_member.uid === member.external_id).first_name?.charAt(0) }}
                </span>
                <span v-if="member.team" class="inline-flex items-center ml-3 text-sm font-normal text-gray-900 break-all">
                  {{ truncate(props.teams.find(prop_member => prop_member.uid === member.external_id).label, 20) }}
                </span>
                <span v-else class="inline-flex items-center ml-3 text-sm font-normal text-gray-900">
                  {{ truncate(props.members.find(prop_member => prop_member.uid === member.external_id).label, 20) }}
                </span>
                <!-- <span
                  v-if="state.resources_overloaded[member.uid]?.includes?.(task.uid)"
                  class="text-error-500 scale-x-[-1] ml-2"
                  v-tippy="props.translate('Resource is overloaded')"
                >
                  <IconHawkMeterMedium class="w-4 h-4" />
                </span> -->
              </div>
              <div class="flex items-center col-span-2 text-sm font-normal text-gray-600">
                <div
                  v-if="state.is_editing"
                  class="flex items-center w-[100px] h-7 pr-2"
                >
                  <input
                    :id="`members_work_${index}`"
                    v-model="state.work[index]"
                    type="number"
                    min="0"
                    class="w-[40px] text-sm font-normal text-gray-900 h-full pl-2 mr-2 bg-transparent border-b border-gray-600 focus:border-primary-600"
                    @paste="handlePaste"
                    @keydown="e => preventKeydown(e)"
                    @keyup="e => keyUp(e, index)"
                    @blur="$event => onBlur($event, index, member, state.work[index])"
                  >
                  <div class="w-full text-sm font-normal text-gray-900 text-end">
                    <template v-if="member.cost_type === 'per_hour'">
                      {{ props.translate('hrs') }}/{{ props.translate('day') }}
                    </template>
                    <template v-else-if="member.cost_type === 'per_item'">
                      {{ props.translate('items') }}
                    </template>
                    <template v-else-if="member.cost_type === 'fixed'">
                      {{ props.translate('cost') }}
                    </template>
                  </div>
                </div>
                <div v-else class="flex items-center gap-2 h-7">
                  {{ state.work[index] }}
                  <div class="text-sm font-normal text-gray-900 text-end">
                    <template v-if="member.cost_type === 'per_hour'">
                      {{ props.translate('hrs') }}/{{ props.translate('day') }}
                    </template>
                    <template v-else-if="member.cost_type === 'per_item'">
                      {{ props.translate('items') }}
                    </template>
                    <template v-else-if="member.cost_type === 'fixed'">
                      {{ props.translate('cost') }}
                    </template>
                  </div>
                </div>
              </div>
              <div
                v-if="props.active_schedule.track_costs"
                :key="state.work[index]"
                v-tippy="{
                  content: getCostString(computeResourcePlannedCost(member, index)).length > 14 ? `<span class='break-all'>${getCostString(computeResourcePlannedCost(member, index))}</div>` : '',
                  allowHTML: true,
                  placement: 'top',
                  appendTo: props.tippy_target,
                }"
                class="flex items-center col-span-2 text-sm font-normal text-gray-900"
              >
                {{ truncate(getCostString(computeResourcePlannedCost(member, index)), 14) }}
              </div>
              <div
                class="flex items-center justify-between col-span-2 text-sm font-normal text-gray-900"
                :class="[
                  props.active_schedule.track_costs && computeResourceActualCost(member) && computeResourcePlannedCost(member, index) < computeResourceActualCost(member) ? '!text-error-500' : '',
                ]"
              >
                <template v-if="props.active_schedule.track_costs">
                  <span
                    v-if="computeResourceActualCost(member)"
                    v-tippy="{
                      content: getCostString(computeResourceActualCost(member)).length > 14 ? `<span class='break-all'>${getCostString(computeResourceActualCost(member))}</div>` : '',
                      allowHTML: true,
                      placement: 'top',
                      appendTo: props.tippy_target,
                    }"
                  >
                    {{ truncate(getCostString(computeResourceActualCost(member)), 14) }}
                  </span>
                  <template v-else>
                    &mdash;
                  </template>
                </template>
              </div>
            </div>
          </template>
          <template v-if="state.filtered_custom_resources.length">
            <div
              class="grid gap-x-3 mb-2"
              :class="{
                'mt-2': state.filtered_members.length,
                'grid-cols-9': props.active_schedule.track_costs,
                'grid-cols-8': !props.active_schedule.track_costs,
              }"
            >
              <div
                class="text-sm font-normal text-gray-600"
                :class="[
                  state.is_editing ? 'ml-[26px]' : '',
                  props.active_schedule.track_costs ? 'col-span-3' : 'col-span-6',
                ]"
              >
                {{ props.translate('Custom resources') }}
              </div>
              <template v-if="!state.filtered_members.length">
                <div class="col-span-2 text-sm font-normal text-gray-600">
                  {{ props.translate('Work') }}
                </div>
                <div
                  v-if="props.active_schedule.track_costs"
                  class="col-span-2 text-sm font-normal text-gray-600"
                >
                  {{ props.translate('Planned') }}
                </div>
                <div
                  v-if="props.active_schedule.track_costs"
                  class="col-span-2 text-sm font-normal text-gray-600"
                >
                  {{ props.translate('Actual') }}
                </div>
              </template>
            </div>
            <template
              v-for="(custom_resource, index) in computed_custom_resources"
              :key="custom_resource.name"
            >
              <div
                v-if="state.filtered_custom_resources.includes(custom_resource.uid)"
                class="grid gap-x-3 py-2 pl-2 -ml-2 rounded-lg group hover:bg-gray-50"
                :class="{
                  'opacity-40': !state.checkboxes[index + computed_members.length] && state.is_editing,
                  'grid-cols-9': props.active_schedule.track_costs,
                  'grid-cols-8': !props.active_schedule.track_costs,
                }"
              >
                <div
                  class="flex items-center text-sm font-normal text-gray-900 cursor-pointer mr-2"
                  :class="[
                    props.active_schedule.track_costs ? 'col-span-3' : 'col-span-6',
                  ]"
                  @click="activateCheckboxIndex(!state.checkboxes[index + computed_members.length], index + computed_members.length, custom_resource)"
                >
                  <HawkCheckbox
                    v-if="state.is_editing"
                    :model-value="state.checkboxes[index + computed_members.length]"
                  />
                  <div class="flex items-center justify-center min-w-6 w-6 h-6 mr-3 font-medium text-gray-600 bg-gray-100 rounded-full text-xs">
                    {{ custom_resource.name.charAt(0) }}
                  </div>
                  <span class="break-all">
                    {{ custom_resource.name }}
                  </span>
                  <!-- <span
                    v-if="state.resources_overloaded[custom_resource.uid]?.includes?.(task.uid)"
                    class="text-error-500 scale-x-[-1] ml-2"
                    :v-tippy="props.translate('Resource is overloaded')"
                  >
                    <IconHawkMeterMedium class="w-4 h-4" />
                  </span> -->
                </div>
                <div class="flex items-center col-span-2 text-sm font-normal text-gray-600">
                  <div
                    v-if="state.is_editing"
                    class="flex items-center w-[100px] h-7 pr-2"
                  >
                    <input
                      :id="`custom_resources_work_${index}`"
                      v-model="state.work[index + computed_members.length]"
                      type="number"
                      min="0"
                      class="w-[40px] text-sm font-normal text-gray-900 h-full pl-2 mr-2 bg-transparent border-b border-gray-600 focus:border-primary-600"
                      @paste="handlePaste"
                      @keydown="e => preventKeydown(e)"
                      @keyup="e => keyUp(e, index + computed_members.length)"
                      @blur="$event => onBlur($event, index + computed_members.length, custom_resource, state.work[index + computed_members.length])"
                    >
                    <div class="w-full text-sm font-normal text-gray-900 text-end">
                      <template v-if="custom_resource.cost_type === 'per_hour'">
                        {{ props.translate('hrs') }}/{{ props.translate('day') }}
                      </template>
                      <template v-else-if="custom_resource.cost_type === 'per_item'">
                        {{ props.translate('items') }}
                      </template>
                      <template v-else-if="custom_resource.cost_type === 'fixed'">
                        {{ props.translate('cost') }}
                      </template>
                    </div>
                  </div>
                  <div
                    v-else
                    class="flex items-center gap-2 h-7"
                  >
                    {{ state.work[index + computed_members.length] }}
                    <div class="text-sm font-normal text-gray-900 text-end">
                      <template v-if="custom_resource.cost_type === 'per_hour'">
                        {{ props.translate('hrs') }}/{{ props.translate('day') }}
                      </template>
                      <template v-else-if="custom_resource.cost_type === 'per_item'">
                        {{ props.translate('items') }}
                      </template>
                      <template v-else-if="custom_resource.cost_type === 'fixed'">
                        {{ props.translate('cost') }}
                      </template>
                    </div>
                  </div>
                </div>
                <div
                  v-if="props.active_schedule.track_costs"
                  v-tippy="{
                    content: getCostString(computeResourcePlannedCost(custom_resource, index + computed_members.length)).length > 14 ? `<span class='break-all'>${getCostString(computeResourcePlannedCost(custom_resource, index + computed_members.length))}</div>` : '',
                    allowHTML: true,
                    placement: 'top',
                    appendTo: props.tippy_target,
                  }"
                  class="flex items-center col-span-2 text-sm font-normal text-gray-900"
                >
                  {{ truncate(getCostString(computeResourcePlannedCost(custom_resource, index + computed_members.length)), 14) }}
                </div>
                <div
                  class="flex items-center justify-between col-span-2 text-sm font-normal text-gray-900"
                  :class="[
                    props.active_schedule.track_costs && computeResourceActualCost(custom_resource) && computeResourcePlannedCost(custom_resource, index + computed_members.length) < computeResourceActualCost(custom_resource) ? '!text-error-500' : '',
                  ]"
                >
                  <template v-if="props.active_schedule.track_costs">
                    <span
                      v-if="computeResourceActualCost(custom_resource)"
                      v-tippy="{
                        content: getCostString(computeResourceActualCost(custom_resource)).length > 14 ? `<span class='break-all'>${getCostString(computeResourceActualCost(custom_resource))}</div>` : '',
                        allowHTML: true,
                        placement: 'top',
                        appendTo: props.tippy_target,
                      }"
                    >
                      {{ truncate(getCostString(computeResourceActualCost(custom_resource)), 14) }}
                    </span>
                    <template v-else>
                      &mdash;
                    </template>
                  </template>
                </div>
              </div>
            </template>
          </template>
        </div>
        <hr v-if="props.active_schedule.track_costs" class="mt-4 border-gray-300">
        <!-- <div v-if="props.active_schedule.track_costs" class="grid mt-4 mr-2" :class="props.active_schedule.track_costs ? 'grid-cols-9' : 'grid-cols-8'">
          <div class="flex justify-end col-span-6 pr-3 text-sm font-normal text-gray-600">
            {{ props.translate('Unassigned') }}
          </div>
          <div class="col-span-2 text-sm font-normal text-gray-600">
            $$$
          </div>
          <div v-if="!is_schedule_editable" class="col-span-2 text-sm font-normal text-gray-600">
            $$$
          </div>
        </div> -->
        <div
          v-if="props.active_schedule.track_costs"
          class="grid grid-cols-9 gap-x-3 my-4 mr-2"
        >
          <div class="flex justify-end col-span-5 pr-3 text-sm font-semibold text-gray-700">
            {{ props.translate('Total') }}
          </div>
          <div
            v-tippy="{
              content: getCostString(total_planned).length > 14 ? `<span class='break-all'>${getCostString(total_planned)}</div>` : '',
              allowHTML: true,
              placement: 'top',
              appendTo: props.tippy_target,
            }"
            class="col-span-2 scrollbar text-sm font-semibold text-gray-700"
          >
            {{ truncate(getCostString(total_planned), 14) }}
          </div>
          <div
            v-if="!is_schedule_editable"
            v-tippy="{
              content: getCostString(total_actual).length > 14 ? `<span class='break-all'>${getCostString(total_actual)}</div>` : '',
              allowHTML: true,
              placement: 'top',
              appendTo: props.tippy_target,
            }"
            class="col-span-2 scrollbar text-sm font-semibold text-gray-700"
            :class="total_planned < total_actual ? '!text-error-500' : ''"
          >
            {{ truncate(getCostString(total_actual), 14) }}
          </div>
        </div>
      </template>
      <div v-else class="text-sm text-gray-400 italic">
        {{ props.translate('No resources found.') }}
      </div>
      <hr class="mt-4 mb-2 border-gray-300">
      <div
        class="flex items-center"
        :class="{
          'justify-end': state.is_editing,
          'justify-between': !state.is_editing,
          '!justify-end': props.view_only,
        }"
      >
        <div v-if="!state.is_editing && !props.view_only" class="text-sm font-semibold text-gray-600 mr-2 cursor-pointer" @click="onUpdateResources">
          {{ props.translate('Update resources') }}
        </div>
        <div class="flex items-center">
          <div class="text-sm font-semibold text-gray-600 mr-2 cursor-pointer" @click="onClose">
            {{ props.translate(state.is_editing ? 'Cancel' : 'Close') }}
          </div>
          <div v-if="state.is_editing && !state.is_saving" class="text-sm font-semibold text-primary-700 cursor-pointer" @click="onSave">
            {{ props.translate('Save') }}
          </div>
          <div v-else-if="state.is_editing && state.is_saving" class="w-[33px]">
            <HawkLoader container_class="" :height="4" :width="4" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
