<script setup>
import dayjs from 'dayjs';
import { useRouter } from 'vue-router';
import { useCommonStore } from '~/common/stores/common.store';
import PmGraphWidget from '~/dashboard/components/widgets/graph-widgets/pm-graph.widget.vue';
import PmIndexWidget from '~/dashboard/components/widgets/pm-widgets/pm-index.widget.vue';

const props = defineProps({
  schedule: {
    type: Object,
    default: () => ({}),
  },
});

const $t = inject('$t');
const $date = inject('$date');
const $services = inject('$services');

const router = useRouter();
const common_store = useCommonStore();

const container_ref$ = ref(null);

const state = reactive({
  versions: [],
  milestones: [],
  is_loading: false,
});

const users_and_teams = computed(() => {
  const users = [];
  const teams = [];
  const all_members = Object.entries(props.schedule?.members);
  all_members.forEach((element) => {
    const new_el = {
      uid: element[0],
      access: element[1],
    };
    const teams_uids = Object.keys(common_store.teams_map);
    if (teams_uids?.includes(new_el.uid))
      teams.push(new_el);
    else users.push(new_el);
  });

  return {
    users,
    teams,
  };
});

function viewScheduleDetails() {
  router.push({ name: 'schedule-details', params: { schedule_uid: props.schedule.uid } });
}

function progress_success_width(planned, current) {
  return {
    width:
      planned < current
        ? `${planned * 100}%`
        : `${current * 100}%`,
  };
}

function progress_width(planned, current) {
  return {
    width:
      planned > current
        ? `${planned * 100 - current * 100}%`
        : `${current * 100 - planned * 100}%`,
  };
}

function getScheduleData(uid) {
  return {
    data: {
      name: 'S curve',
      type: 's_curve',
      schedule: uid,
      s_curve_type: 'progress',
      s_curve_interval: 'month',
      chart_type: null,
      chart_field: null,
      chart_value: null,
      chart_breakdown: null,
      number_chart_field: null,
      module: 'project management',
    },
  };
}

function getPIData(uid, type) {
  return {
    data: {
      name: null,
      type,
      schedule: uid,
      s_curve_type: null,
      s_curve_interval: null,
      chart_type: null,
      chart_field: null,
      chart_value: null,
      chart_breakdown: null,
      history_compare: null,
      history_duration: 1,
      number_chart_field: null,
      module: 'project management',
    },
  };
}

function getLatestDate(date_one, date_two) {
  if (!date_one)
    return date_two;
  if (!date_two)
    return date_one;
  return dayjs(date_one).isAfter(dayjs(date_two)) ? date_one : date_two;
}

watch(() => props.schedule.uid, async () => {
  state.is_loading = true;
  if (container_ref$.value) {
    container_ref$.value.scrollTop = 0;
  }
  const { data } = await $services.project_management.get({
    url: 'project-management/schedules',
    id: props.schedule.uid,
    query: { select: 'versions,activities', activities_type: 'MILESTONE' },
  });
  state.milestones = data.data[0].activities;
  state.milestones.sort((a, b) => dayjs(a.planned_start).diff(dayjs(b.planned_start)));
  state.versions = data.data[0].versions;
  state.is_loading = false;
}, { immediate: true });
</script>

<template>
  <div ref="container_ref$" class="border border-gray-200 rounded-xl p-4 w-3/4 max-h-[calc(100vh-200px)] scrollbar">
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-3 text-md text-gray-900 font-semibold">
        <HawkText
          :content="props.schedule.name"
          :length="65"
          class="cursor-pointer hover:text-primary-600"
          @click="viewScheduleDetails"
        />
        <HawkBadge v-if="schedule.progress < schedule.percent_schedule_complete" color="yellow">
          {{ $t("Delayed") }}
        </HawkBadge>
        <HawkBadge v-else-if="schedule.progress >= schedule.percent_schedule_complete" color="blue">
          {{ $t("On track") }}
        </HawkBadge>
        <IconHawkEdit
          v-if="!props.schedule.is_published"
          v-tippy="{
            content: $t('Unpublished schedule'),
            placement: 'top',
          }"
          class="w-4 h-4"
        />
      </div>
      <HawkButton type="light" @click="viewScheduleDetails">
        {{ $t('View details') }}
        <IconHawkChevronRight />
      </HawkButton>
    </div>
    <hr class="mt-3 mb-4">
    <div class="flex">
      <div class="w-full">
        <div class="flex items-center justify-between mb-3">
          <div class="text-sm font-semibold">
            {{ $t('Milestones') }}
          </div>
          <div>
            <IconHawkTrophyFilled class="inline mr-1 text-warning-400" />
            <span class="text-sm font-semibold text-gray-900">
              {{ schedule.scheduled_milestones || 0 }}/{{ schedule.total_milestones || 0 }}
            </span>
          </div>
        </div>
        <HawkLoader v-if="state.is_loading" />
        <div v-else class="max-h-[calc(100vh-76vh)] scrollbar">
          <div v-for="milestone in state.milestones" :key="milestone.uid" class="flex items-center justify-between gap-2 p-1 w-full">
            <div class="w-full flex items-center gap-1 text-xs font-normal text-gray-600">
              <span
                v-tippy="{
                  content: milestone.text.length > 35 ? milestone.text : '',
                  placement: 'top',
                }"
                class="truncate max-w-[calc(100vw-80vw)]"
              >
                {{ milestone.text }}
              </span>
              <IconHawkAlertTriangle
                v-if="dayjs().isAfter(dayjs(milestone.planned_start)) && milestone.progress !== 1"
                v-tippy="{
                  content: $t('Overdue'),
                  placement: 'top',
                }"
                class="w-4 h-4 inline fill-warning-500 text-white"
              />
            </div>
            <div class="min-w-[150px] flex justify-end items-center gap-3 text-xs font-normal text-gray-600">
              {{ $date(milestone.planned_start, 'DD MMMM YYYY') }}
              <IconHawkCheckCircleGreen v-if="milestone.progress === 1" class="w-4 h-4" />
              <div v-else class="mx-0.5 w-[12px] h-[12px] border border-primary-600 rounded-full" />
            </div>
          </div>
        </div>
      </div>
      <div class="border-l border-gray-200 mx-8" />
      <div class="min-w-[30vw]">
        <div class="grid grid-cols-3 gap-y-10">
          <div class="col-span-2">
            <div class="flex flex-col gap-1">
              <div class="text-sm font-semibold text-gray-900">
                {{ $t('Progress') }}
              </div>
              <div class="text-xs font-normal text-gray-900">
                <div class="inline-flex items-start bg-gray-300 rounded w-[200px] overflow-hidden mr-2">
                  <div
                    class="h-[8px] bg-primary-500 rounded-l"
                    :style="progress_success_width(props.schedule.percent_schedule_complete, props.schedule.progress)"
                  />
                  <div
                    class="h-[8px] rounded-r"
                    :class="{
                      'rounded-l': progress_success_width(props.schedule.percent_schedule_complete, props.schedule.progress).width === '0%',
                      'bg-error-500': props.schedule.percent_schedule_complete > props.schedule.progress,
                      'bg-primary-500': props.schedule.percent_schedule_complete <= props.schedule.progress,
                    }"
                    :style="progress_width(props.schedule.percent_schedule_complete, props.schedule.progress)"
                  />
                </div>
                {{ Math.round((schedule.progress || 0) * 100) }}%
              </div>
            </div>
          </div>
          <div class="col-span-1">
            <div class="flex flex-col gap-1">
              <div class="text-sm font-semibold text-gray-900">
                {{ $t('Last updated') }}
              </div>
              <div class="text-xs font-normal text-gray-900">
                <template v-if="!props.schedule?.is_published">
                  {{ $date(getLatestDate(props.schedule.updated_at, props.schedule.created_at), 'DD MMMM YYYY, hh:mma') }}
                </template>
                <template v-else>
                  {{ $date(getLatestDate(props.schedule.last_progress_updated_at, getLatestDate(props.schedule.updated_at, props.schedule.created_at)), 'DD MMMM YYYY, hh:mma') }}
                </template>
              </div>
            </div>
          </div>
          <div class="col-span-1">
            <div class="flex flex-col gap-1">
              <div class="text-sm font-semibold text-gray-900">
                {{ $t('Planned start') }}
              </div>
              <div
                class="text-xs font-normal"
                :class="dayjs().isAfter(dayjs(props.schedule.planned_start ?? props.schedule.start)) && props.schedule.progress === 0 ? 'text-error-700' : 'text-gray-900'"
              >
                {{ $date(props.schedule.planned_start ?? props.schedule.start, 'DD MMMM YYYY') }}
              </div>
            </div>
          </div>
          <div class="col-span-1">
            <div class="flex flex-col gap-1">
              <div class="text-sm font-semibold text-gray-900">
                {{ $t('Planned finish') }}
              </div>
              <div
                class="text-xs font-normal"
                :class="dayjs().isAfter(dayjs(props.schedule.planned_finish ?? props.schedule.finish)) && props.schedule.progress !== 1 ? 'text-error-700' : 'text-gray-900'"
              >
                {{ $date(props.schedule.planned_finish ?? props.schedule.finish, 'DD MMMM YYYY') }}
              </div>
            </div>
          </div>
          <div class="col-span-1">
            <div class="flex flex-col gap-1">
              <div class="text-sm font-semibold text-gray-900">
                {{ $t('Revisions') }}
              </div>
              <HawkLoader v-if="state.is_loading" container_class="!inline" :height="4" :width="4" />
              <div v-else class="text-xs font-normal text-gray-900">
                {{ state.versions.length }}
              </div>
            </div>
          </div>
          <div class="col-span-1">
            <div class="flex flex-col gap-1">
              <div class="text-sm font-semibold text-gray-900">
                {{ $t('Shared with') }}
              </div>
              <div class="text-xs font-normal text-gray-900">
                <div v-if="props.schedule.public">
                  {{ $t('Everyone') }}
                </div>
                <HawkMembers
                  v-else
                  :members="users_and_teams.users.concat(users_and_teams.teams)"
                  :max_badges_to_display="5"
                  :has_avatar="true"
                  popover_position="right"
                  size="xs"
                  type="group"
                />
              </div>
            </div>
          </div>
          <div class="col-span-2">
            <div class="flex flex-col gap-1">
              <div class="text-sm font-semibold text-gray-900">
                {{ $t('Created by') }}
              </div>
              <div class="flex items-center text-xs font-medium text-gray-700">
                <HawkMembers :members="props.schedule.owner.uid" size="tiny" type="label" />
                {{ $date(props.schedule.created_at, 'DD MMMM YYYY') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-4 mb-3">
    <div>
      <div v-if="props.schedule.is_published" class="bg-white cursor-auto w-full h-[380px]" @click.stop>
        <div class="h-[300px] -mt-2">
          <PmGraphWidget :id="props.schedule.uid" :data="getScheduleData(props.schedule.uid)" />
        </div>
        <div class="flex justify-evenly h-[50px]">
          <div class="flex flex-col">
            <div class="text-xs font-medium text-gray-600">
              % {{ $t('Cumulative Planned') }}
            </div>
            <div class="text-lg font-semibold text-primary-700">
              {{ Math.round((schedule.percent_schedule_complete || 0) * 100) }}%
            </div>
          </div>
          <div class="flex flex-col">
            <div class="text-xs font-medium text-gray-600">
              % {{ $t('Cumulative Actual') }}
            </div>
            <div
              class="text-lg font-semibold"
              :class="{
                'text-red-500': schedule.percent_schedule_complete > schedule.progress,
                'text-primary-700': schedule.percent_schedule_complete <= schedule.progress,
              }"
            >
              {{ Math.round((schedule.progress || 0) * 100) }}%
            </div>
          </div>
          <div class="flex flex-col">
            <div class="text-xs font-medium text-gray-600">
              {{ $t('Schedule Performance Index (SPI)') }}
            </div>
            <PmIndexWidget :id="props.schedule.uid" :data="getPIData(props.schedule.uid, 'spi')" class="text-center" />
          </div>
          <div class="flex flex-col">
            <div class="text-xs font-medium text-gray-600">
              {{ $t('Cost Performance Index (CPI)') }}
            </div>
            <PmIndexWidget :id="props.schedule.uid" :data="getPIData(props.schedule.uid, 'cpi')" class="text-center" />
          </div>
        </div>
      </div>
      <div v-else class="w-full mt-20 flex justify-center items-center text-lg font-semibold text-gray-400">
        {{ $t('Metrics unavailable for unpublished schedules') }}
      </div>
    </div>
  </div>
</template>
