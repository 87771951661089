<script setup>
import { cloneDeep } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const props = defineProps({
  mode: {
    type: String,
    default: 'create',
  },
  existingItems: {
    type: Array,
    default: () => [],
  },
  prefillData: {
    type: Object,
    default: () => ({}),
  },
  save: {
    type: Function,
    required: false,
  },
});

const emit = defineEmits(['close', 'save', 'addAnother']);

const $t = inject('$t');

const project_management_store = useProjectManagementStore();
const { active_schedule, is_fullscreen } = storeToRefs(project_management_store);

const access_level_options = [
  {
    value: 'read',
    label: $t('Viewer'),
  },
  {
    value: 'manage',
    label: $t('Manager'),
  },
  {
    value: 'write',
    label: $t('Admin'),
  },
];

const form$ = ref(null);

const state = reactive({
  form_data: cloneDeep(props.prefillData),
  add_another: false,
  is_loading: false,
  assignee_key: 0,
});

const cost_type_items = computed(() => {
  return [
    {
      value: 'per_hour',
      label: $t('Per hour'),
    },
    ...(
      state.form_data.resource_type === 'custom'
        ? [{
            value: 'per_item',
            label: $t('Per item'),
          }]
        : []
    ),
    {
      value: 'fixed',
      label: $t('Fixed'),
    },
  ];
});

async function onSave() {
  const payload = cloneDeep(state.form_data);
  if (payload.cost)
    payload.cost = Number.parseFloat(state.form_data.cost.replaceAll(',', ''));
  if (props.mode === 'edit') {
    if (state.form_data.resource_type === 'custom')
      payload.resource_name = { name: payload.resource_name, uid: payload.resource_name };
    state.is_loading = true;
    emit('save', payload);
  }
  else {
    if (state.form_data.resource_type === 'custom')
      payload.resource_name = { name: payload.resource_name, uid: payload.resource_name };
    await props.save(payload);
    if (state.add_another) {
      state.add_another = false;
      form$.value.reset();
      state.assignee_key++;
      emit('addAnother');
    }
    else {
      emit('close');
    }
  }
}

function onSelect(option) {
  state.form_data.access_level = active_schedule.value.members[option.uid] ?? 'read';
}

watch(() => state.form_data.resource_type, () => {
  if (state.form_data.resource_type === 'member') {
    state.form_data.resource_name = null;
    if (state.form_data.cost_type === 'per_item')
      state.form_data.cost_type = 'per_hour';
  }
  else {
    state.form_data.resource_name = '';
  }
});

onMounted(() => {
  if (state.form_data.resource_type === 'custom' && props.mode === 'edit')
    state.form_data.resource_name = state.form_data.resource_name.name;
});
</script>

<template>
  <HawkModalContainer
    id="pm-resource-modal-container"
    :options="{ teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body' }"
  >
    <Vueform
      ref="form$"
      v-model="state.form_data"
      sync
      size="sm"
      :display-errors="false"
      :display-messages="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div class="flex flex-col justify-start">
              <template v-if="props.mode === 'create'">
                {{ $t('New resource') }}
              </template>
              <template v-else-if="props.mode === 'edit'">
                {{ $t('Edit resource') }}
              </template>
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <RadiogroupElement
            name="resource_type"
            :label="$t('Type')"
            :items="[
              { value: 'member', label: $t('Member') },
              { value: 'custom', label: $t('Custom') },
            ]"
            default="member"
            :disabled="props.mode === 'edit'"
            class="mb-6"
          />
          <TextElement
            v-if="state.form_data.resource_type === 'custom'"
            name="resource_name"
            :label="$t('Resource')"
            :placeholder="$t('Type the name of the resource')"
            :rules="['required']"
          />
          <HawkAssigneeInput
            v-else
            :key="state.assignee_key"
            :options="{
              name: 'resource_name',
              label: $t('Resource'),
              canClear: false,
              rules: 'required',
              placeholder: $t('Select member'),
              existing_users: props.existingItems,
              existing_teams: props.existingItems,
              size: 'sm',
              has_teams: false,
              create: false,
              closeOnDeselect: true,
              appendNewOption: true,
              disabled: props.mode === 'edit',
              appendTo: '#pm-resource-modal-container',
              onSelect,
            }"
            class="mb-6"
          >
            <template #option="{ uid }">
              <div class="absolute left-0 flex items-center h-full pl-3">
                <HawkMembers
                  :members="uid"
                  size="xs"
                  type="label"
                />
              </div>
            </template>
            <template #single-label="{ uid }">
              <div class="absolute left-0 flex items-center h-full pl-3">
                <HawkMembers
                  :members="uid"
                  size="xs"
                  type="label"
                />
              </div>
            </template>
          </HawkAssigneeInput>
          <SelectElement
            v-if="
              (state.form_data?.resource_name
                && state.form_data.resource_type !== 'custom')
                || props.prefillData.access_level
            "
            :key="state.form_data?.resource_name"
            name="access_level"
            :label="$t('Access level')"
            :default="active_schedule.members[state.form_data?.resource_name?.uid] ?? 'read'"
            :can-clear="false"
            :can-deselect="false"
            :items="access_level_options"
            :native="false"
            rules="required"
            append-to="#pm-resource-modal-container"
          >
            <template v-if="active_schedule.members[state.form_data?.resource_name?.uid]" #description>
              {{ $t('Current access') }}:
              {{ access_level_options.find(option => option.value === active_schedule.members[state.form_data?.resource_name?.uid])?.label }}
            </template>
          </SelectElement>
          <template v-if="active_schedule.track_costs">
            <SelectElement
              name="cost_type"
              :class="state.form_data?.cost_type !== 'fixed' ? 'my-6' : 'mt-6'"
              :can-clear="false"
              :can-deselect="false"
              :label="$t('Cost type')"
              :native="false"
              default="per_hour"
              :items="cost_type_items"
              rules="required"
              append-to="#pm-resource-modal-container"
            />
            <TextElement
              v-if="state.form_data?.cost_type !== 'fixed'"
              name="cost"
              :label="$t('Cost')"
              :rules="['required']"
              :mask="{
                mask: 'number',
                thousandsSeparator: ',',
                scale: 2,
                padFractionalZeros: false,
                normalizeZeros: false,
                radix: '.',
                mapToRadix: ['.'],
                min: 0,
                autofix: false,
              }"
            >
              <template #addon-before>
                {{ active_schedule?.currency?.symbol }}
              </template>
            </TextElement>
          </template>
          <div
            v-if="
              state.form_data?.resource_name
                && state.form_data.resource_type !== 'custom'
                && !active_schedule.members[state.form_data?.resource_name?.uid]
            "
            class="mt-6 mb-2 border border-gray-300 bg-gray-25 flex items-center gap-3 rounded-lg p-4"
          >
            <IconHawkInfoCircle class="text-gray-600" />
            <div class="text-sm font-normal text-gray-700 w-fit">
              {{ $t(`The selected resource doesn't have access to this schedule. The schedule will be shared with them at the chosen access level once saved.`) }}
            </div>
          </div>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end w-full col-span-full">
              <HawkButton
                type="text"
                class="mr-4"
                :secondary="true"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </HawkButton>
              <ButtonElement
                v-if="props.mode === 'create'"
                name="save_and_add"
                secondary
                class="mr-4"
                submits
                @click="state.add_another = true"
              >
                {{ $t('Save and add another') }}
              </ButtonElement>
              <ButtonElement
                name="save"
                :loading="state.is_loading"
                submits
              >
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
