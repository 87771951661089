<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { pick } from 'lodash-es';
import { reactive } from 'vue';
import { useModal } from 'vue-final-modal';
import HawkColumnsSelector from '~/common/components/organisms/hawk-columns-selector.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { csvInjectionProtector } from '~/common/utils/common.utils.js';
import FeatureDetailsMenu from '~/terra/components/feature-details/feature-details-menu.vue';
import TableImportPopup from '~/terra/components/table/terra-table-import-popup.vue';
import { useTerraStore } from '~/terra/store/terra.store';

const props = defineProps({
  table_instance: {
    type: Object,
  },
  table_options: {
    type: Object,
  },
  table_data: {
    type: Array,
  },

  sortData: {
    type: Function,
  },

  saveChanges: {
    type: Function,
  },
  can_modify_feature: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['openCreationPopup', 'hideSaveChanges', 'onColumnsOrdered', 'expandTable', 'updateData', 'updateColumnsOrder', 'close', 'openPivotTable', 'updateTableSettings', 'onSearch']);
const {
  $t,
} = useCommonImports();
const sort_columns_modal = useModal({
  component: HawkColumnsSelector,
  attrs: {
    onClose() {
      sort_columns_modal.close();
    },
    texts: {
      heading: 'Choose columns',
      left_heading: 'Available columns',
      right_heading: 'Added columns',
    },
    is_nested: false,
    immediate: false,
    label_field_name: 'name',
  },
});
const import_modal = useModal({
  component: TableImportPopup,
  attrs: {
    onClose() {
      import_modal.close();
    },
  },
});

const terra_store = useTerraStore();

const state = reactive({
  is_exporting: false,
  sorting_type: 'Horizontal',
  sorting_order: 'asc',
});

const hawk_menu_orientation_items = computed(() => {
  return [
    {
      uid: 'Horizontal',
      label: 'Horizontal',
      on_click: () => {
        state.sorting_type = 'Horizontal';
        props.sortData(true, state.sorting_type, state.sorting_order);
      },
    },
    {
      uid: 'Vertical',
      label: 'Vertical',
      on_click: () => {
        state.sorting_type = 'Vertical';
        props.sortData(true, state.sorting_type, state.sorting_order);
      },
    },
  ];
});
const hawk_menu_sort_items = computed(() => {
  const items = {
    Horizontal: {
      asc: 'Left to right',
      desc: 'Right to left',
    },
    Vertical: {
      asc: 'Bottom to top',
      desc: 'Top to bottom',
    },
  };
  return [
    {
      uid: items[state.sorting_type].asc,
      label: items[state.sorting_type].asc,
      on_click: () => {
        state.sorting_order = 'asc';
        props.sortData(true, state.sorting_type, state.sorting_order);
      },
    },
    {
      uid: items[state.sorting_type].desc,
      label: items[state.sorting_type].desc,
      on_click: () => {
        state.sorting_order = 'desc';
        props.sortData(true, state.sorting_type, state.sorting_order);
      },
    },
  ];
});

function getSelectedRows() {
  const selected_column_values = props.table_instance?.getDataAtCol(0) || [];
  const selected_columns_data = Object.values(props.table_options.data).map(val => val.selected);
  if (selected_column_values?.[0] === null && !selected_columns_data.length)
    return [];
  return selected_column_values.filter(val => val);
}
async function updateFeatureType(val) {
  terra_store.is_loading = true;
  const table_data = props.table_options.data;

  terra_store.selected_features = terra_store.selected_features.map((item) => {
    item.properties.oldfeatureTypeId = item.properties.featureTypeId;
    item.properties.featureType = val.uid;
    item.properties.featureTypeId = val.id;
    if (table_data[item.properties.uid])
      table_data[item.properties.uid]['Feature type'] = terra_store.feature_types[val.id].name;
    return item;
  });
  await terra_store.create_or_update_selected_features();
  terra_store.terra_track_events('Table select row action change feature type', { count: terra_store.selected_features.length });
  emit('updateData', table_data);
  emit('updateTableSettings');
  terra_store.is_loading = false;
}
async function onExportClicked() {
  state.is_exporting = true;
  const exportPlugin = props.table_instance.getPlugin('exportFile');
  const string = exportPlugin.exportAsString('csv', {
    filename: 'export',
  });
  let parsed = Papa.parse(string)?.data || [];
  parsed = parsed.map((row, row_index) => {
    const row_data = props.table_instance.getDataAtRow(row_index);
    const csv_row_data = row_data.reduce((acc, column, i) => {
      // Skip Checkbox and Fly To columns
      if (i >= 2) {
        if (props.table_options.columns[i].renderer === 'attachment-renderer') {
          const data = (column || []).map(item => item?.url || item?.name).filter(val => !!val).join(', ');
          column = csvInjectionProtector(data ?? '');
        }

        else if (i > 2) { // Skip uid passing to csvInjectionProtector
          column = csvInjectionProtector(String(column ?? ''));
        }
        acc.push(column);
      }
      return acc;
    }, []);
    return csv_row_data;
  });
  const header_columns = props.table_options.columns.reduce((acc, header, i) => {
    if (i >= 2) // Skip Checkbox, Fly To columns
      acc.push(header.data);
    return acc;
  }, []);
  parsed.splice(0, 0, header_columns);

  try {
    const ExcelJS = await import('exceljs');
    const { saveAs } = await import('file-saver');

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Features');

    parsed.forEach((row, rowIndex) => {
      row.forEach((cell, colIndex) => {
        worksheet.getCell(rowIndex + 1, colIndex + 1).value = cell;
      });
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), 'export.xlsx');
    terra_store.terra_track_events('Exported table');
  }
  catch (error) {
    logger.error(error);
  }
  state.is_exporting = false;
}
function openSortColumnsPopup() {
  let keys = Object.keys(terra_store.table_state.ordered_keys_map);
  const all_columns = Object.keys(Object.values(props.table_options.data)?.[0] || {}).filter(key => ![...Object.keys(props.table_options.order), 'project'].includes(key));
  if (!keys.length)
    keys = all_columns;
  sort_columns_modal.patchOptions({
    attrs: {
      all_columns: all_columns.map(key => ({ name: key.replace('smProperties_', ''), id: key })),
      active_columns: keys.map(key => ({ name: key.replace('smProperties_', ''), id: key })),
      onSave(event) {
        terra_store.table_state.ordered_keys_map = event.reduce((acc, column, i) => {
          acc[column.id] = i + 1;
          return acc;
        }, {});
        emit('onColumnsOrdered');
        terra_store.terra_track_events('Table columns changed');
      },
    },
  });
  sort_columns_modal.open();
}
function openImportPopup() {
  import_modal.patchOptions({
    attrs: {
      table_options: props.table_options,
      async on_import(import_data) {
        const table_data = props.table_options.data;
        import_data.forEach((row) => {
          if (table_data[row.uid])
            table_data[row.uid] = { ...row, ...pick(table_data[row.uid], ['selected', 'Fly to', 'Feature type', 'Layer', 'Sublayer', ...Object.keys(table_data[row.uid]).filter(val => val.startsWith('smProperties'))]) };
        });
        emit('updateData', table_data);
        await props.saveChanges({ all_rows: true });
        emit('updateColumnsOrder', true);
        terra_store.terra_track_events('Table import');
        import_modal.close();
      },
    },
  });
  import_modal.open();
}
</script>

<template>
  <div class="p-4 border-gray-100 h-16 flex justify-between items-center">
    <div v-if="!getSelectedRows().length" class="flex items-center">
      <div class="w-60">
        <HawkSearchInput
          :model-value="terra_store.table_state.search"
          full_width
          :placeholder="$t('Search')"
          :debounce_time="0"
          @update:model-value="emit('onSearch', $event)"
        />
      </div>
      <div v-if="table_options.show_filter_loader" class="ml-2">
        <HawkLoader :height="5" :width="5" container_class="m-0" />
      </div>
    </div>
    <div
      v-else
      class="flex items-center"
      :class="{ 'pointer-events-none opacity-70': getSelectedRows().length !== terra_store.selected_features.length }"
    >
      <div class="flex items-center">
        <FeatureDetailsMenu
          used_for="table"
          :hawk_menu_items="[]"
          :show_action_button="true"
          @open-creation-popup="emit('openCreationPopup', $event)"
          @update-feature-type="updateFeatureType($event)"
        />
      </div>

      <p class="text-gray-700 text-sm mx-4">
        {{ `${getSelectedRows().length} ${$t('selected')}` }}
      </p>
      <p
        v-if="getSelectedRows().length === terra_store.selected_features.length"
        class="font-semibold text-sm text-gray-600 cursor-pointer"
        @click="terra_store.selected_features = []; terra_store.terra_track_events('Table clear selection');"
      >
        {{ $t('Clear') }}
      </p>
      <HawkLoader v-else :height="5" :width="5" container_class="m-0" />
    </div>
    <div class="flex items-center justify-end">
      <div class="flex items-center ml-3 mt-1">
        <HawkButton v-if="can_modify_feature && table_options.show_save_changes" type="link" color="primary" :loading="table_options.is_saving" @click="saveChanges">
          {{ $t('Save Changes') }}
        </HawkButton>
      </div>
      <div class="flex items-center">
        <div class="cursor-pointer px-2 ml-3" @click="onExportClicked()">
          <IconHawkUploadOne v-if="!state.is_exporting" class="text-gray-600 w-5 h-5" />
          <HawkLoader v-else :height="5" :width="5" container_class="m-0" />
        </div>
        <div class="cursor-pointer px-2 ml-3" @click="emit('expandTable')">
          <IconHawkExpandOne class="text-gray-600 w-5 h-5" />
        </div>
      </div>
      <HawkMenu
        additional_trigger_classes="!ring-0"
      >
        <template #trigger>
          <div class="cursor-pointer px-3 -mb-1">
            <IconHawkDotsVertical class="text-gray-600 w-5 h-5" />
          </div>
        </template>
        <template #content>
          <div class="w-60 py-3 px-4 text-sm font-medium">
            <hawk-menu
              :items="hawk_menu_orientation_items"
              additional_dropdown_classes="right-full !-top-0 mr-4 !mt-0 !bottom-auto"
              additional_trigger_classes="!ring-0"
              position="bottom-left"
            >
              <template #trigger>
                <div class="flex items-center h-9 hover:bg-gray-50 rounded-lg py-2 px-3 -ml-3 w-[232px]">
                  <div class="text-sm text-ellipsis overflow-hidden relative whitespace-nowrap">
                    <span class="text-gray-500">{{ $t('Orientation') }}: </span>
                    <span class="font-medium">{{ state.sorting_type }}</span>
                  </div>
                  <IconHawkChevronRight class="ml-auto" />
                </div>
              </template>
            </hawk-menu>
            <hawk-menu
              :items="hawk_menu_sort_items"
              additional_dropdown_classes="right-full !-top-0 mr-4 !mt-0 !bottom-auto"
              additional_trigger_classes="!ring-0"
              position="bottom-left"
            >
              <template #trigger>
                <div class="flex items-center h-9 hover:bg-gray-50 rounded-lg py-2 px-3 -ml-3 w-[232px]">
                  <div class="text-sm text-ellipsis overflow-hidden relative whitespace-nowrap">
                    <span class="text-gray-500">{{ $t('Sort') }}: </span>
                    <span v-if="state.sorting_type === 'Horizontal'" class="font-medium">{{ state.sorting_order === 'asc' ? 'Left to right' : 'Right to left' }}</span>
                    <span v-if="state.sorting_type === 'Vertical'" class="font-medium">{{ state.sorting_order === 'asc' ? 'Bottom to top' : 'Top to bottom' }}</span>
                  </div>
                  <IconHawkChevronRight class="ml-auto" />
                </div>
              </template>
            </hawk-menu>
            <div class="flex items-center h-9 hover:bg-gray-50 rounded-lg py-2 px-3 -ml-3 w-[232px] cursor-pointer" @click="openSortColumnsPopup()">
              {{ $t('Columns') }}
            </div>
            <div
              v-tippy="{ content: can_modify_feature ? '' : $t(`You don't have permissions to perform this action.`) }"
              :class="{ 'opacity-60': !can_modify_feature }"
              class="flex items-center h-9 hover:bg-gray-50 rounded-lg py-2 px-3 -ml-3 w-[232px] cursor-pointer"
              @click="can_modify_feature && openImportPopup()"
            >
              {{ $t('Import') }}
            </div>
          </div>
        </template>
      </HawkMenu>
      <div class="cursor-pointer px-2" @click="emit('close')">
        <IconHawkXClose class="text-gray-600 w-5 h-5" />
      </div>
    </div>
  </div>
</template>
